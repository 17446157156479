import React from "react";
import styled from "styled-components";
import {
    Nav,
    NavItem,
    NavLink,
  } from "reactstrap";

const Wrap = styled.div`
background: #fff;
display: flex; 
flex-direction: row; 
align-items: flex-start; 
justify-content: space-around; 
flex-shrink: 0; 
width: 100%; 
padding: 35px;
position: relative; 
overflow: hidden;
flex-wrap: wrap;
@media screen and (max-width: 678px) {
    flex-direction: column;
  }
`;

const StyledNavLink = styled(NavLink)`
    color: ${props => props.theme.grey02ST};   
    text-align: left;
    padding-left: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    position: relative;
    &:hover {
        color: ${props => props.theme.redST};
    }
`;

const Element = styled.div``;



export default function Footer3ST({}) {
  return (
      <Wrap>
        <Element>
            <h2 className="small left">SELFTISSUS</h2>
            <Nav vertical>
                <NavItem>
                    <StyledNavLink
                    active
                    href="/selftissus/notre-concept"
                    >
                    Notre concept
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/selftissus/nos-magasins">
                    Nos magasins
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/selftissus/nos-ateliers">
                    Nos ateliers
                    </StyledNavLink>
                </NavItem>
            </Nav>
        </Element>
        
        <Element>
            <h2 className="small left">NOS SERVICES</h2>
            <Nav vertical>
                <NavItem>
                    <StyledNavLink
                    active
                    href="/nos-services/nous-contacter"
                    >
                    Nous contacter
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/nos-services/livraison-et-retours">
                    Livraison & Retours
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/nos-services/aide">
                    Aide
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/article/22450-p-carte-cadeau-10-2580">
                    Offrir une carte cadeau
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/nos-services/pro-demander-un-devis">
                    Pro : demander un devis
                    </StyledNavLink>
                </NavItem>
            </Nav>
        </Element>
        
        <Element>
            <h2 className="small left">SELF TISSUS ET VOUS</h2>
            <Nav vertical>
                <NavItem>
                    <StyledNavLink
                    active
                    href="/communaute/rejoignez-notre-communaute/"
                    >
                    Rejoignez notre communauté
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/decouvrez-votre-niveau-de-couture/">
                    Découvrez votre niveau de couture
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/trouvez-de-l'inspiration/">
                    Trouvez de l'inspiration
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/apprenez-a-votre-rythme/">
                    Apprenez à votre rythme
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/decouvrez-nos-tutos/">
                    Découvrez nos tutos
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/votre-fidelite-recompensee/">
                    Votre fidélité récompensée
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/communaute/votre-avis-compte/">
                    Votre avis compte
                    </StyledNavLink>
                </NavItem>
            </Nav>
        </Element>
        
        <Element>
            <h2 className="small left">INFORMATIONS LÉGALES</h2>
            <Nav vertical>
                <NavItem>
                    <StyledNavLink
                    active
                    href="/informations-legales/mentions-legales"
                    >
                    Mentions légales
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/informations-legales/cgv">
                    CGV
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/informations-legales/cgu">
                    CGU
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/informations-legales/donnees-personnelles">
                    Données personnelles
                    </StyledNavLink>
                </NavItem>
                <NavItem>
                    <StyledNavLink href="/informations-legales/plan-du-site">
                    Plan du site
                    </StyledNavLink>
                </NavItem>
            </Nav>
        </Element>
        
      </Wrap>
  );
}
