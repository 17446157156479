import React from "react";
import styled from "styled-components";
import  {Form ,Label, Input, FormGroup} from "reactstrap";
import { message, Upload } from 'antd';
const { Dragger } = Upload;

export const InputStyled = styled(Input)`
/* width: auto; */
position: relative;
font-family: "Assistant", sans-serif !important;
padding: 1rem 1.05rem;
border-radius: 0;
border-color: ${props => props.theme.grey02ST};
&.min {
  min-width: 200px;
}
&.info {
  border-color: ${props => props.theme.redST};
  &:focus {
    box-shadow: 0 0 0 .25rem #df5a4e42;
  }
}
`

export const LabelStyled = styled(Label)`
position: relative;
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
width: -webkit-fill-available;
`

export const FormStyled = styled(Form)`
display: flex;
align-items: center;
gap: 50px;
`
export const FormGroupStyled = styled(FormGroup)`
margin-bottom: 0 !important;
`

export const Legal = styled.div`
font-family: "Assistant", sans-serif;
font-size: 14px;
color: ${props => props.theme.grey02ST};
text-align: justify;
`

export const FormConnect = styled(FormGroup)`
position: relative;
width: 100%;
max-width: 660px;
margin: ${props => props.margin ? props.margin : "10px auto !important"};
&.bordered {
  border: #000 1px solid;
}
&.max {
  max-width: 100%;
}
&.mobileForm {
  display: none;
  max-width: 100%;
  &>input {
    padding: 0.875rem .75rem;
    font-size: 15.5px;
  }
}
@media only screen and (max-width: 1199px) {
&.mobileForm {
  display: flex;
}
}  
`

export const DraggerStyled = styled(Dragger)`
display: block;
width: 100%;
max-width: 660px;
margin: 20px auto 10px !important;
&.w600 {
  width: 600px;
}
&>.ant-upload {
  border: 2px dashed ${props => props.theme.orangeST} !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ant-upload-drag-icon {
  font-size: 24px;
  color: ${props => props.theme.orangeST};
}

@media only screen and (max-width: 768px) {
&.w600 {
  width: 100%;
}
}

`



