import { useEffect, useState,useRef } from 'react';
import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import styled from 'styled-components';
import 'react-tooltip/dist/react-tooltip.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const BandeauWrapper = styled.div`
background-color: transparent;
height: 470px;
width: calc(100% + 200px);  
margin-left: -100px; 
margin-right: -100px; 
position: relative;   
&:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
height: 300px;
}
`;

const SwiperStyled = styled(Swiper)`
padding-bottom :50px ;
height: 500px;
box-shadow: 0 0 7px rgba(0,0,0,0.5);
@media screen and (max-width: 768px) {
height: 300px;
}
`;

const ImageStyled = styled.div`
width: 100%;
height: 100%;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
box-shadow: 0 0 7px rgba(0,0,0,0.5);
@media screen and (max-width: 768px) {
height: 300px;
background-size: contain;
}
`

export default function HeroHomeST ({data}) {
  const swiperRef = useRef(null);
  useEffect(() => {
    register();
    swiperRef.current.addEventListener('slidechange', (e) => {
      // console.log(e.detail);
    });

  });
  

  return (
    <BandeauWrapper>
      <SwiperStyled ref={swiperRef}
        grabCursor={true}
        loop={true}
        // autoplay={{pauseOnMouseEnter: true, delay: 3000, disableOnInteraction:false}}
        slideActiveClass="active"
        slideNextClass="next"
        slidePrevClass="prev"
        slidesPerView= {1}
        navigation={false}
        pagination={{ clickable: true }}
        modules={[Navigation, Autoplay, Pagination]}
      >
       
        {
          data && data?.Bandeau?.map((item, i) => {
            return (
              <SwiperSlide key={i} onClick={() => window.location.href = item?.Url}>
                <ImageStyled style={{backgroundImage: `url(${item?.Image?.url})`}}
                 aria-label={item?.Image?.alt}
                />
              </SwiperSlide>
            );
          })
        }
      </SwiperStyled>
   </BandeauWrapper>
  )
}

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}