import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { Section } from "../DesignSystem/SelfTissus/ContainersST";
import { Card } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Layout } from 'antd';
import { 
    TabContent, TabPane, 
    Row, Col , Nav, NavItem, NavLink,
  } from 'reactstrap'; 
//   import { NavLinkStyled, NavItemStyled } from "../DesignSystem/Commons/Onglets";
import ArticlesListeST from "../ArticlesListe/ArticlesListe";
import AteliersListeST from "../Atelier/AteliersListe";

const NavStyled = styled(Nav)`
    background-color: #79b69fc9;
    padding: 10px 0;
    `
const NavItemStyled = styled(NavItem)`
margin: 0 30px;
 @media only screen and (max-width: 768px) {
    width: 75%;
    margin: 10px 0;
  }
`;
const NavLinkStyled = styled(NavLink)`
 font-size: 22px;
  color: #fff !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px dashed transparent !important;
  &.active {
    border: 2px dashed #fff !important;
    cursor: default;
  }
  &:hover{
    border: 2px dashed #fff !important;
    text-decoration: none;
  }
`;

export default function ProduitsListeST({dataArticles, dataAteliers, cats}) {
  const [activeTab, setActiveTab] = useState(0);
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    const newTabData = [];
    if (dataArticles && dataArticles.length > 0) {
      newTabData.push({ label: "Articles", content: <ArticlesListeST data={dataArticles} cats={cats} /> });
    }
    if (dataAteliers && dataAteliers.length > 0) {
      newTabData.push({ label: "Ateliers", content: <AteliersListeST data={dataAteliers} /> });
    }
    setTabData(newTabData);
  }, [dataArticles, dataAteliers, cats]);

  useEffect(() => {
    if (tabData.length > 0 && activeTab >= tabData.length) {
      setActiveTab(0); // Reset to the first available tab if the current active tab is out of bounds
    }  
  }, [tabData, activeTab]);
   
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
    return (
    <>
   
      <Section>
        <div>
            <NavStyled tabs style={{justifyContent:"center", marginBottom:30}} className="ignoreParent">
              {tabData.map((tab, index) => (
                <NavItemStyled className={index} key={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </NavStyled>
            <TabContent activeTab={activeTab}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
            </div>
      </Section>
      </>
  );
}