import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Tag } from "antd";
import { FormGroup, Label } from "reactstrap";
import ImageGallery from 'react-image-gallery';
import {
  GlassMagnifier,
} from "react-image-magnifiers";
import ReactHtmlParser from 'react-html-parser';
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import BoutonFavouriteST from "../DesignSystem/SelfTissus/BoutonFavourite";
import {Badge} from 'antd';
import {WrapFormPop} from "../DesignSystem/PopUpST";
import PopPanierST from "../PopupsST/PopPanier";
import PopLocalisationST from "../PopupsST/PopLocalisation";
import { FaRegHandPointRight } from "react-icons/fa6";
import picDefault from "../../assets/images/photo.jpg";
import FavoriHeartST from "../Favoris/FavoriHeart";
import ShareST from "../Share/ShareST";
import PopErrorST from "../PopupsST/PopError";

const Wrap = styled.div`
display: flex;
align-items: flex-start;
gap: 50px;
@media screen and (max-width: 786px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
`;
const WrapGal = styled.div`
width: 50%;
min-width: 350px;
margin-top: 20px;
@media screen and (max-width: 786px) {
    width: 100%;
}
`;

const ImageGalleryStyled = styled(ImageGallery)`
&>div>div>.image-gallery-swipe > div > div.image-gallery-slide.image-gallery-center > image {
    max-height: 50vh;
    max-width: 50vh;
  }
  `;

const Prod = styled.a`
display: flex !important;
color: ${props => props.theme.greenST} !important;
align-items: center;
justify-content: flex-start;
gap: 10px;
font-size: 14px;
&:hover {
  color: ${props => props.theme.orangeST} !important;
}
`;

const ProdsSmall = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
gap: 10px;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const TagStyled = styled(Tag)`
font-size: 16px;
padding: 5px 10px;
`;

const Prices = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 18px;
gap: 20px;
`

const New = styled.span`
font-weight: 700;
font-size: 28px;
`
const TxtSmall = styled.div`
color: ${(props) => props.theme.grey09ST};
font-size: 14px;
`;

const Buts = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
justify-content: flex-start;
align-content: flex-start;
flex-wrap: wrap;
`;
const Quantity = styled.div`
position: relative;
padding: 20px 0;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`;

const Prix = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
font-size: 20px;
font-weight: 700;
span {
  color: ${(props) => props.theme.redST};
  font-size: 24px;
}
`;

const Details = styled.div`
padding: 20px 0;
&>* {
  color: ${props => props.theme.grey06ST}; 
}
`;

const Img = styled.img`
max-width: 50%;
width: 50%;
margin-top: 20px;
height: auto;
object-fit: contain;
@media screen and (max-width: 786px) {
    max-width: 100%;
}
`;

const Cont = styled.div`
width: 50%;
@media screen and (max-width: 786px) {
    width: 100%;
}
`;

const Planifs = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
align-items: center;
gap: 20px;
padding: 20px 0 5px 0px;
`;

const Planif = styled.button`
min-width: 180px;
max-width: 180px;
min-height: 100px;
max-height: 100px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
background-color: #fff;
padding: 10px;
border-radius: 5px;
border: 1px solid #A29995;
&.selected {
  background-color: #e1dcdc !important;
  border-color: #79b69f !important;
  color: ${props => props.theme.orangeST};
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
&:hover {
  border: 1px solid ${props => props.theme.orangeST};
  color: ${props => props.theme.orangeST};
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
@media screen and (max-width: 786px) {
    min-width: 100%;
    max-width: 100%;
}
`;

const Message = styled.span`
position: absolute;
bottom: 10px;
color: ${(props) => props.theme.orangeST};
font-size: 14px;
`;


export default function AtelierFicheST({data}) {
  console.log("dataxcxc", data);
  const dispatch = useDispatch();
  const basket = useSelector(state => state.basket);
  const favoris = useSelector(state => state.favoris);
  const [quantity, setQuantity] = useState(1);
  const unitPrice = data?.PUTTC; 
  const [selected, setSelected] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [changeMag, setChangeMag] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [showStockMessage, setShowStockMessage] = useState(false);
  const selectedPlanif = data?.Planification?.find(planif => planif?.ID === selectedId);
  const maxQuantity = selectedPlanif ? selectedPlanif?.PlaceLibre : 999;

  const galleryItems = data && data?.Photo && data?.Photo?.map(photo => {
    return {
      original: photo?.Photo?.url ? photo?.Photo?.url : picDefault,
      thumbnail: photo?.Photo?.url ? photo?.Photo?.url : picDefault,
      description: photo?.Photo?.alt,
      originalAlt: photo?.Photo?.alt,
    };
  });
  const showThumbnails = galleryItems?.length > 1;
  const renderGalleryItem = (item) => (
    <GlassMagnifier
      imageSrc={item.original}
      imageAlt={item.alt}
      largeImageSrc={item.original} // Optional
    />
  );
  const handleSelect = (id) => {
    setSelected(prev => prev === id ? null : id);
  };
  const handleClick = (id) => {
    setSelectedId(id);
    setQuantity(1);
    setShowStockMessage(false);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (isNaN(newQuantity) || newQuantity < 1) {
      setQuantity(1);
      setShowStockMessage(false);
      return;
    }
    setQuantity(newQuantity);
    if (newQuantity === maxQuantity) {
      setShowStockMessage(true);
    } else {
      setShowStockMessage(false);
    }
  };

const getColorByDifficulte = (difficulte) => {
  switch (difficulte) {
    case 1:
      return '#13c2c2';
    case 2:
      return '#722ED1';
    case 3:
      return '#EB2F96';
    default:
      return '#000';
  }
};

function formatDuration(duration) {
  const [hours, minutes] = duration.split(':');
  const formattedHours = `${parseInt(hours)}h`;
  const formattedMinutes = minutes !== '00' ? `${parseInt(minutes)}` : '';
  return `${formattedHours}${formattedMinutes}`;
}
const [isFav, setIsFav] = useState(false);
const totalPrice = (quantity * unitPrice).toFixed(2);
const existingItem = favoris.items.find(item => item.ID === data.ID);
const found = !!existingItem;

useEffect(() => {
  setIsFav(found);
}, [found]);

const handleFavouriteChange = (newFavouriteState,article) => {
  setIsFav(newFavouriteState);
  favorisAjout(article,newFavouriteState);
};
const favorisAjout = (article,isFavF) => {
  setIsFav(isFavF)
  if (!existingItem) {
    dispatch({
      type: "ADD_TO_FAVORIS",
      payload: { ...article, quantity: 1, mode: "atelier"},
    });
  }  else {
    dispatch({
      type: "DELETE_FAVORIS",
      payload: article.ID,
    });
  }
}

const panierAjout = (article) => {
  if (data?.Planification && !selectedId) {
    setIsErrorVisible(true);
    setTxt("Veuillez choisir une date pour l'atelier.");
    return;
  }
   // Find existing item in the basket with the same ID and planifId in the Planification array
   const existingItem = basket.items.find(item => 
    item.ID === article.ID && 
    item.planifId === selectedId
  );

  if (existingItem) {
    // Update the quantity if the item with the same ID and planifId exists in the basket
    dispatch({
      type: "UPDATE_BASKET",
      payload: {
        id: article.ID,
        quantity: Number(existingItem.quantity) + Number(quantity),
        planifId:  existingItem.planifId
      }
    });
  } else {
    // Add to basket if the item does not exist in the basket
    dispatch({
      type: "ADD_TO_BASKET",
      payload: { ...article, quantity: quantity, mode: "atelier", planifId: selectedId },
    });
  }

  setShowForm(true);
};

  return (
  <>
  {isErrorVisible && <PopErrorST message={txt} titre={"ATELIER"} onClick={() => setIsErrorVisible(false)}/>}
  <Badge.Ribbon text={"Difficulté : "+data?.Difficulte} color={getColorByDifficulte(data?.Difficulte)} placement={"start"}>
   <Wrap className="desc">
   <FavoriHeartST isFav={isFav} onClick={() => handleFavouriteChange(!isFav,data)}/>
   <ShareST shareUrl={data?.slug} title="" className={""} media={data?.img?.url}/>
      {/* <Img
      src={data?.Photo?.[0]?.Photo?.url || picDefault}
      alt={data?.Photo?.[0]?.Photo?.alt || "image de l'atelier"}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = picDefault;
      }}
      loading="lazy"
    /> */}
    <WrapGal>
    {galleryItems?.length > 0 ? <ImageGalleryStyled className="img" items={galleryItems} 
      thumbnailPosition="bottom" 
      showPlayButton={false} 
      showFullscreenButton={true} 
      showIndex={false} 
      slideInterval={5000}
      showThumbnails={showThumbnails}
      renderItem={renderGalleryItem}
      // renderThumbInner={renderThumbInner}
    /> : null}
    </WrapGal>
    <Cont>
    <h1 className="left">{data?.lib}</h1>
    <Prices>
    <New>{data?.PUTTC +" €"}</New>
    </Prices>
    <TxtSmall>Taxes incluses.</TxtSmall>
    {data?.Planification && <Planifs>
      { data?.Planification?.map((planif, i) => {
        return (
          <Planif
                key={planif?.ID}
                onClick={() => handleClick(planif?.ID)}
                className={selectedId === planif?.ID ? 'selected' : ''}
              >
            <span>Le {planif?.Date} à {planif?.Heure}</span>
            <span>Lieu : {planif?.Lieu}</span>
            <span>Places libres : {planif?.PlaceLibre}</span>
          </Planif>
        );
      })}
      <Planif
        key={"0"}
        onClick={() => handleClick("000")}
        className={selectedId === "000" ? 'selected' : ''}
      >
        <span>Réservation non planifiée</span>
      </Planif>
    </Planifs>}
    <hr></hr>
    <TagStyled color="green">Durée : {formatDuration(data?.Duree)}</TagStyled>
    <Details>
      {ReactHtmlParser(data?.Description)}
      <ProdsSmall>
        {data?.Articles && <hr />}
        {data?.Articles?.map((prod, i) => (
          prod?.libArticle && (
            <Prod key={i} href={prod?.slug}>
              <FaRegHandPointRight />
              {prod?.libArticle}
            </Prod>
          )
        ))}
        </ProdsSmall>
    </Details>
    <Buts>
      <Quantity>
        <FormGroup floating>
          <InputStyled
             className="min"
             id="prix"
             name="number"
             placeholder="Quantité"
             type="number"
             value={quantity}
             onChange={handleQuantityChange}
             min={data?.CommandeMinimum}
             max={maxQuantity}
             step={data?.CommandeFormatDecimale === 1 ? "1" : data?.CommandeFormatDecimale === 2 ? "0.1" : data?.CommandeFormatDecimale === 3 ? "0.01" : "1"}
          />
            <Label for="prix">
            Quantité
            </Label>
          </FormGroup>
          {showStockMessage && <Message>Nombre de places maximum atteint</Message>}
          <Prix>Prix total : <span>{totalPrice} €</span></Prix>
        </Quantity>
      <BoutonBlancBtnST lib="Ajouter au panier" width="100%"  onClick={() => panierAjout(data)}/>
      <BoutonFavouriteST isFav={isFav} lib="Pour un futur projet couture" width="100%" onClick={() => handleFavouriteChange(!isFav,data)}/>
    </Buts>
    </Cont>
    </Wrap>
    </Badge.Ribbon>
     {showForm === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form"><PopPanierST onClick={() => setShowForm(false)} data={data} quantity={quantity} totalPrice={totalPrice} mode="atelier"/></WrapFormPop>,
               document.body
     ) : ""}
     {changeMag === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form">
        <PopLocalisationST onClick={() => setChangeMag(false)}/>
        </WrapFormPop>,
        document.body
     ) : ""}
     </>
  );
}


