import React from "react";
import styled from "styled-components";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Badge } from 'antd';
import picDefault from "../../assets/images/photo.jpg";
import {
  GlassMagnifier,
} from "react-image-magnifiers";


const Wrap = styled.div`
  width: 50%;
  min-width: 350px;
  margin-top: 20px;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
  `;

const Ref = styled.div`
background-color: #ced1d987;
padding: 10px;
border: solid 1px ${(props) => props.theme.grey01ST};
box-shadow: 0 0 5px rgba(0,0,0,0.1);
margin-top: 20px;
`;

const ImageGalleryStyled = styled(ImageGallery)`
&>div>div>.image-gallery-swipe > div > div.image-gallery-slide.image-gallery-center > image {
    max-height: 50vh;
    max-width: 50vh;
  }
  `;

export default function ArticlePhotoST({data, dataProd}) {

  const getBadgeProps = (dataProd) => {
    if (dataProd?.ArticleSemaine) {
      return { text: "Article de la semaine", color: "#79b69f" };
    } else if (dataProd?.Abajourable) {
      return { text: "Abajourable", color: "#da46da" };
    } else if (dataProd?.Nouveaute) {
      return { text: "Nouveauté", color: "#07af5b" };
    } else if (dataProd?.PromoMois) {
      return { text: "Promo du mois", color: "#5089d7" };
    } else if (dataProd?.PromoWeb) {
      return { text: "Promo Web", color: "#f05b32" };
    }
    return { text: "", color: "" };
  };

  const badgeProps = dataProd ? getBadgeProps(dataProd) : { text: "", color: "" };


  const galleryItems = dataProd && dataProd?.Photos && dataProd?.Photos.length > 0
  ? dataProd?.Photos.map(photo => {
      return {
        original: photo?.image?.url ? photo?.image?.url : picDefault,
        thumbnail: photo?.image?.url ? photo?.image?.url : picDefault,
        description: photo?.image?.alt,
        originalAlt: photo?.image?.alt,
      };
    })
  : [{
      original: picDefault,
      thumbnail: picDefault,
      description: 'Default Image',
      originalAlt: 'Default Image',
    }];
  
      

  const renderGalleryItem = (item) => (
    <GlassMagnifier
      imageSrc={item?.original}
      imageAlt={item?.alt}
      largeImageSrc={item?.original} // Optional
    />
  );

  const renderThumbInner = (item) => (
    <img
      style={{maxWidth:"50px"}}
      src={item.thumbnail}
      alt={item.alt}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = picDefault;
      }}
      loading="lazy"
    />
  );

  const showThumbnails = galleryItems?.length > 1;
  return (
    <Wrap>
    <Badge.Ribbon text={badgeProps.text} color={badgeProps.color} placement={"start"} style={{display:(badgeProps.text !== "") ? "block" : "none"}}>
    {galleryItems?.length > 0 ? <ImageGalleryStyled className="img" items={galleryItems} 
      thumbnailPosition="bottom" 
      showPlayButton={false} 
      showFullscreenButton={true} 
      showIndex={false} 
      slideInterval={5000}
      showThumbnails={showThumbnails}
      renderItem={renderGalleryItem}
      // renderThumbInner={renderThumbInner}
    /> : null}
  </Badge.Ribbon>
  </Wrap>
  );
}

