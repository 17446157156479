import React, {useState,useEffect} from "react";
import { navigate } from '@reach/router';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useGeolocated } from "react-geolocated";
import { FormConnect,InputStyled } from "../DesignSystem/SelfTissus/Forms";
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import PopErrorST from "../PopupsST/PopError";
import { AiOutlineSearch } from "react-icons/ai";
import BoutonLocST from "../DesignSystem/SelfTissus/BoutonLoc";
import { BoutonVertSearch } from "../DesignSystem/Commons/Buttons";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { AiOutlineArrowRight } from "react-icons/ai";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
gap: 10px;
`

const Geo = styled.div`
position: relative;
font-family: "Assistant", sans-serif !important;
padding: 1rem 1.05rem;
border-radius: 0;
border: #A29995 1px solid;
background-color: #fff;
`

const Villes = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 10px;
`
const VilleWrapper = styled.button`
min-width: 250px;
max-width: 250px;
min-height: 120px;
max-height: 120px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
background-color: #fff;
padding: 10px;
border-radius: 5px;
border: 1px solid #A29995;
transition: all 0.3s;
&.pop {
  min-width: 100%;
  max-width: 100%;
}
&.selected, .id-2.selected {
  background-color: #e1dcdc !important;
  border-color: ${(props) => props.theme.greenST} !important;
  border-width: 2px !important;
}
&.id-2 {
  order: 1;
  background-color: #79b69f8c;
}
&:hover {
  background-color: #F9F9F9;
  border-color: ${(props) => props.theme.orangeST};
}
@media screen and (max-width: 786px) {
  min-width: 100%;
  max-width: 100%;
}
`
const Ville = styled.div`
font-weight: 600;
`
const Distance = styled.div`
font-weight: 600;
color: ${(props) => props.theme.orangeST};
`

const Selected = styled.button`
background: transparent;
border: none;
color: ${props => props.theme.greenST};
font-weight: bold;
margin-bottom: 10px;
font-size: 20px;
&>span {
    display: block;
}
&:hover {
    color: ${props => props.theme.orangeST};
}
`


export default function FormLocalisationST({className,onClick, mode, mode2}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [magasins, setMagasins] = useState([]);
    const [selectedMagasinName, setSelectedMagasinName] = useState('');
    const [coordinatesList, setCoordinatesList] = useState([]);
    const [txt, setTxt] = useState('');
    const [city, setCity] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
    const [sortedMagasins, setSortedMagasins] = useState([]);
    const [selectedMagasinObj, setSelectedMagasinObj] = useState(null);
    const dispatch = useDispatch();
    const mag = useSelector(state => state.magasin);
    const [selectedMagasin, setSelectedMagasin] = useState('');
    const [hideList, setHideList] = useState(false);
console.log("selectedMagasinObj1111",selectedMagasinObj);
    // const addMag = () => {
    //   setIsFav(isFavF)
    //   if (!existingItem) {
    //     dispatch({
    //       type: "ADD_TO_FAVORIS",
    //       payload: { ...article, quantity: 1, mode : "article" },
    //     });
    //   }  else {
    //     dispatch({
    //       type: "DELETE_FAVORIS",
    //       payload: article.ID,
    //     });
    //   }
    // }
    useEffect(() => {
      axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
      .then((response) => {
        const filteredMagasins = response?.data?.Magasin.filter(magasin => magasin.uiD !== "50ec9764-679e-4acf-8945-61a97ffcb8d5" && magasin.uiD !== "977f6921-ed0a-44e7-9425-742d52bd62ea");
        setMagasins(filteredMagasins);
      })
      .catch((err) => {
        console.log("err", err);
      });
    }, []);

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const toRad = (value) => (value * Math.PI) / 180;
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in kilometers
    };

    const handleButtonClick = () => {
      
      if (selectedMagasin) {
        console.log("selectedMagasin", selectedMagasin);
          const selectedMagasinObj = magasins.find(magasin => magasin.uiD === selectedMagasin);
          if (selectedMagasinObj) {
            console.log("selectedMagasinObj", selectedMagasinObj);
            setSelectedMagasinObj(selectedMagasinObj);
            dispatch({
                type: "SET_MAGASIN",
                payload: {
                    ID: selectedMagasinObj.uiD,
                    Nom: selectedMagasinObj.Nom
                }
            });
              dispatch({
                type: "SET_MODELIVR",
                payload: {
                  modeLivraison: 2
                }
              });
            if (mode2 === "panier") {
              dispatch({
                type: "SET_ADRESSE",
                payload: {
                  IDAdresse: selectedMagasinObj.uiD,
                },
              });
              const element = document.getElementById("mode");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }
           
        } else {
            dispatch({
                type: "SET_MAGASIN",
                payload: {
                    ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
                    Nom: "WEB"
                }
            });
        }
        setIsErrorVisible(true);
        setTxt(`Magasin sélectionné:<br> ${selectedMagasinObj.Nom}`);
        
        if (mode === "pop") {
          onClick();
          navigate(1)
        }
      }
  };
    const {
      coords,
      getPosition,
      isGeolocationAvailable,
      isGeolocationEnabled,
      positionError,
    } = useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
      watchLocationPermissionChange: true,
    });
  
    const getLoc = () => {
      setIsLoading(true);
      if (coords) {
        axios
          .get(
            `https://api-adresse.data.gouv.fr/reverse/?lon=${coords.longitude}&lat=${coords.latitude}`
          )
          .then((response) => {
            console.log("Geolocation response:", response.data); // Log response for debugging
            const result = response.data.features[0];
            const properties = result?.properties;
            const cityName = properties?.city || properties?.town || properties?.village || "";
            setCity(cityName);
            handleSearch(cityName);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching location data:", error);
            setIsLoading(false);
              });
          } else {
            console.error("Coordinates are not available");
            setIsLoading(false);
          }
        };


          const searchCityCoordinates = (searchTerm, setCoordinates) => {
            console.log("Function called with searchTerm:", searchTerm);
            if (!searchTerm || !searchTerm.trim()) {
              console.error("Search term input is empty");
              return;
            }
          
            let query = "";
            query = `q=${searchTerm}`;
            console.log("Query formed:", query);
          
            console.log("Starting API call...");
            axios
              .get(`https://api-adresse.data.gouv.fr/search/?${query}`)
              .then((response) => {
                console.log("API response:", response); // Log the entire response
                if (response.data.features.length > 0) {
                  console.log("Search results:", response.data.features); // Log results for debugging
                  const result = response.data.features[0];
                  console.log("Search result:", result); // Log result for debugging
                  const coordinates = result.geometry.coordinates || [0, 0];
                  setCoordinates({ latitude: coordinates[1], longitude: coordinates[0] });
                  console.log("City coordinates:", coordinates);
                } else {
                  console.error("No results found for the given search term.");
                }
              })
              .catch((error) => {
                console.error("Error during API call:", error);
                if (error.response) {
                  console.error("Response data:", error.response.data);
                } else if (error.request) {
                  console.error("Request data:", error.request);
                } else {
                  console.error("Error message:", error.message);
                }
              });
          };

          const fetchCoordinates = async (cityName) => {
            try {
              const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${cityName}`);
              if (response.data.features.length > 0) {
                const result = response.data.features[0];
                const coordinates = result.geometry.coordinates || [0, 0];
                return { latitude: coordinates[1], longitude: coordinates[0] };
              } else {
                console.error(`No results found for city : ${cityName}`);
                setIsErrorVisible(true);
                setTxt(`Aucun résultat trouvé pour la ville : ${cityName}`);
                return { latitude: 0, longitude: 0 };
              }
            } catch (error) {
              console.error(`Error fetching coordinates for city : ${cityName}`, error);
              return { latitude: 0, longitude: 0 };
            }
          };

           useEffect(() => {
            const fetchAllCoordinates = async () => {
              const coordinatesPromises = magasins.map(async (magasin) => {
                const coords = await fetchCoordinates(magasin.Ville);
                const distance = calculateDistance(coordinates.latitude, coordinates.longitude, coords.latitude, coords.longitude);
                return { ...magasin, coordinates: coords, distance };
              });
              const results = await Promise.all(coordinatesPromises);
              results.sort((a, b) => a.distance - b.distance);
              setSortedMagasins(results);
            };

            if (coordinates.latitude !== 0 && coordinates.longitude !== 0) {
              fetchAllCoordinates();
            }
          }, [coordinates, magasins]);

          const handleSearch = async (cityName) => {
            console.log("Searching for city:", cityName);
            const coords = await fetchCoordinates(cityName);
            setCoordinates(coords);
          };

        return(
        <>
        {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
        {isLoading && <SpinnerST />}
        {!hideList ? (
        <Wrap>
          <p>
            Choissisez votre magasin pour afficher les produits disponibles en
            click and collect près de chez vous
          </p>
          <FormConnect className={[className]}>
            <InputStyled
              id="search"
              name="search"
              placeholder={"Ville ou code postal"}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BoutonVertSearch onClick={() => handleSearch(searchTerm)}>
              <span><AiOutlineSearch /></span>
            </BoutonVertSearch>
          </FormConnect>
          <FormConnect className={[className]}>
            <Geo>Me géolocaliser <AiOutlineArrowRight /></Geo>
            <BoutonVertSearch onClick={getLoc}>
              <span><FaLocationCrosshairs /></span>
            </BoutonVertSearch>
          </FormConnect>

          {!isGeolocationAvailable ? (
            <div>Veuillez autoriser la géolocalisation sur navigateur </div>
          ) : !isGeolocationEnabled ? (
            <div>La géolocalisation n'est pas activée</div>
          ) : coords ? (
            <div>
              {/* <p>Latitude: {coords.latitude}</p>
              <p>Longitude: {coords.longitude}</p> */}
              {/* <p>Votre ville: {city}</p> */}
            </div>
          ) : (
            <div>Getting the location data...</div>
          )}
          {positionError && (
            <p>
              {positionError.code === 1
                ? "L'utilisateur a refusé la géolocalisation"
                : positionError.message}
            </p>
          )}

          <Villes>
            {sortedMagasins.map((magasin, index) => (
              <VilleWrapper
                key={index}
                onClick={() => setSelectedMagasin(magasin.uiD)}
                className={`id-${magasin.ID} ${selectedMagasin === magasin.uiD ? 'selected' : ''} ${mode}`}
              >
                <Ville>
                  {magasin.NomCommercial}
                  <br />
                  {"(" + magasin.Ville + ")"}
                </Ville>
                <Distance>Distance : {magasin.distance.toFixed(2)} km</Distance>
              </VilleWrapper>
            ))}
          </Villes>

          <BoutonST
            lib={"Appliquer"}
            onClick={() => {
              handleButtonClick();
              setHideList(true);
            }}
            background={props => props.theme.orangeST}
            width={"300px"}
          />
        </Wrap>
      ) : (
        <Selected onClick={() => setHideList(!hideList)}>
          <span>{selectedMagasinObj?.NomCommercial}</span>
          <span>{selectedMagasinObj?.Adresse1}</span>
          <span>{selectedMagasinObj?.Adresse2}</span>
          <span>{selectedMagasinObj?.CP} {selectedMagasinObj?.Ville}</span>
        </Selected>
      )}
    </>
  );
};

