import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import {Wrap} from "./ElementsMembre";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import {StyledFade} from "../DesignSystem/SelfTissus/ContainersST";
import ReactHtmlParser from 'react-html-parser';
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import { List } from 'antd';

const ListItemStyled = styled(List.Item)`
width: 100%;
border: 1px solid ${(props) => props.theme.grey01ST} !important;
border-radius: 8px;
padding: 20px;
margin: 0 0 20px 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
&:nth-child(odd) {
    background-color: ${(props) => props.theme.grey08ST};
  }
&>.ant-list-item-extra {
  display: flex;
  align-items: end;
  margin-inline-start: 0 !important;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap !important;
  overflow-x: scroll;
  &>.ant-list-item-extra {
    margin: 0 !important;
    &> div > a {
      padding: 5px 0;
    }
  }
  }
`;
const Ligne = styled.div`
width: 100%;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
@media only screen and (max-width: 768px) {
  overflow-x: scroll;
  }
`;
const Buts = styled.div`
display: flex;
gap: 20px;
align-items: center;
justify-content: center;
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  }
`;

export default function MembreFacturesST({className}) {

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

 const [dataFactures, setDataFactures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [dlPatienter, setDlPatienter] = useState(false);

  const [openedItems, setOpenedItems] = useState({});

  const toggleOpen = (id) => {
    setOpenedItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/membreoperationliste`,{Nature : "F" })
    .then((response) => {
      console.log("response", response);
      setDataFactures(response?.data?.Commandes);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);

  // const handleDownload = async (event, token, num) => {
  //   event.preventDefault();
  //   setDlPatienter(true);
  //   axios({
  //     method: 'post',
  //     url: '/users/download',
  //     data: { TokenDL: token },
  //     responseType: 'blob',
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', `${num}.pdf`);
  //       document.body.appendChild(link);
  //       link.click();
  //       setDlPatienter(false);
  //     })
  //     .catch((err) => {
  //       setIsErrorVisible(true);
  //       setDlPatienter(false);
  //     });
  // };
  const handleDownload = async (event, token, num) => {
    event.preventDefault();
    event.stopPropagation();
    setDlPatienter(true);
    try {
      const response = await axios.post(`/users/download/`,{ "TokenDL":token});
      const base64String = response.data.content;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${num}.pdf`); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download the file:', error);
      setDlPatienter(false);
    }
  
  };
  const viewDocument = async (event, token) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const response = await axios.post('/users/download/', { "TokenDL": token });
      const base64String = response.data.content;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Failed to view the document:', error);
    }
  };

    return (
      <Wrap>
       {isLoading && <SpinnerInlineST/>}
        {dataFactures ? <>
        
            <h3 className="center homemadeTitle initial orange">Mes factures</h3>
            
            <List style={{width:"100%"}}
                itemLayout="vertical"
                size="large"
                pagination={{
                    position: 'bottom',
                    align: 'start',
                    total: dataFactures?.length || 0,
                    showTotal: (total, range) => `Commande ${range[0]}-${range[1]} sur ${total} au total`, // Function to display the range of items and total
                    defaultPageSize: 5, // Number of items per page by default
                    defaultCurrent: 1, // Initial page
                    pageSizeOptions: ['10', '20', '30'], // Options for changing the page size
                    // onChange: (page, pageSize) => {
                    //   // Function to handle page change
                    // },
                  }}
                  dataSource={dataFactures && dataFactures}
                  renderItem={(item) => {
                    console.log("Rendering item:", item); 
                    return (
                      <ListItemStyled key={item?.ID} isOpen={openedItems[item.ID]}>
                            <div>
                              <p>Facture n° <b className="orange">{item?.NumDoc}</b></p>
                              <p>Date : <span className="orange">{formatDate(item?.Date)}</span></p>
                              <p>Montant total : <span className="orange">{item?.MontantTTC}€</span></p>
                            <div>
                              <Buts>
                                <BoutonST background={"#79b69f"} 
                                className="medium block" 
                                width={"250px"} 
                                lib={openedItems[item.ID] ? "Moins de détails" : "Plus de détails"}
                                margin={"0"}
                                onClick={() => toggleOpen(item.ID)}/> 
                                <BoutonST background={"#f05b32"} 
                                className="medium block" 
                                width={"250px"} 
                                margin={"0"}
                                lib={"Télécharger la facture"}
                                onClick={(event) => handleDownload(event, item?.TokenDL, item?.NumDoc)}
                                />
                                <BoutonST background={"#ffce00"} 
                                className="medium block" 
                                width={"250px"} 
                                margin={"0"}
                                lib={"Visualiser la facture"}
                                onClick={(event) => viewDocument(event, item?.TokenDL)}
                                />
                              </Buts>
                             {openedItems[item.ID] && <StyledFade className="flex mt20">
                              <Ligne>
                                   <table className="greenTable">
                                   <thead>
                                     <tr>
                                       <th className="greenTeble">Article</th>
                                       <th>Quantité</th>
                                       <th>Prix PUTTC</th>
                                       <th>Prix TTC</th>
                                     </tr>
                                   </thead>
                                   <tbody>
                                     {item?.Lignes?.map((ligne, index) => (
                                       <tr key={index}>
                                         <td>{ReactHtmlParser(ligne?.Lib)}</td>
                                         <td>{ligne?.Quantite}</td>
                                         <td>{ligne?.PUTTC}€</td>
                                         <td>{ligne?.MontantTTC}€</td>
                                       </tr>
                                     ))}
                                       <tr>
                                        <td colSpan="4">Total : <b className="green">{item?.MontantTTC}€</b></td>
                                      </tr>
                                   </tbody>
                                 </table>
                              </Ligne>
                              </StyledFade>}
                              </div>
                            </div>
                          
                        </ListItemStyled>
                    );
                  }}
                />
                </> : <h3 className="center homemadeTitle initial orange">Vous n'avez pas de factures</h3>}
        </Wrap>
  );
}
