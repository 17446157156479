import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { Drawer,Layout, Menu, Space } from "antd";
import Logo from "../../assets/images/Logo.svg";
import FormSearchST from "../FormsST/FormSearchSelf";
import { AiOutlineEnvironment,AiOutlineHeart,AiOutlineUser,AiOutlineShoppingCart,AiOutlineClose,AiOutlineMenu } from "react-icons/ai";
import BoutonDeconnexiontST from "../DesignSystem/SelfTissus/BoutonDeconnexiontST";
import FavorisST from "../Favoris/Favoris";
import PanierAccueilST from "../Panier/PanierAccueil";
import LocalisationMobST from "../Localisation/LocalisationMob";
import FormConnexionST from "../FormsST/FormConnexion";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { TxtInfo } from "../DesignSystem/SelfTissus/ElementsST";
import DrawerMenu from "./DrawerMenuMobST";
import {Socials, LinkStyledSocial} from "../DesignSystem/SelfTissus/ElementsST";
import { FaFacebook,FaYoutube,FaPinterest,FaInstagram,FaCheck } from "react-icons/fa";
import { Logged, Number, Ville } from "../DesignSystem/Commons/Elements";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
const { Sider } = Layout;

const StyledDrawer = styled(Drawer)`
width: 400px;
@media screen and (max-width: 400px) {
  width: 100%;
}
  & > .ant-drawer-body {
    padding: 10px 0 !important;
  } 
  & >.ant-drawer-header > .ant-drawer-extra {
    width: 100%;
  }
  & >.ant-drawer-header > .ant-drawer-header-title {
    display: none;
  }
  & > .ant-drawer-body > aside {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    & > div > ul > li > div > i {
    }
  }
`;

const StyledImg = styled.img`
`;


const Icons=styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap:15px;
&>svg {
  width: 25px;
  height: 25px;
  fill: ${(props) => props.theme.grey04ST};
    &:hover {
      cursor: pointer;
      fill: ${(props) => props.theme.redST};
    }
  } 

  `
  
const Box=styled.div`
width: 100%;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
padding: 20px;
&.menu {
  padding: 0;
}
`

const Title=styled.p`
font-size: 23px;
color: ${(props) => props.theme.greenST};
font-family: "Homemade Apple", sans-serif;
`

const SpaceStyled=styled(Space)`
width: 100%;
display: flex;
flex-direction: column;
&>.ant-space-item {
  width: 100%;
  &> div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 375px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}
`

const User=styled.div`
position: relative;
  &>svg {
  width: 25px;
  height: 25px;
  fill: ${(props) => props.theme.grey04ST};
    &:hover {
      cursor: pointer;
      fill: ${(props) => props.theme.redST};
    }
  }

`

const SiderStyled=styled(Sider)`
width: 100px; 
&>.ant-layout-sider-children>div>div> aside  {
  max-width: 100%;
min-width: 100%;
width: 100%;
}

@media screen and (max-width: 400px) {
  width: 100%;
}
`;


const items = [
  {
    label: 'Mes commandes',
    key: 'commandes',
  },
  {
    label: 'Mes factures',
    key: 'factures',
  },
  {
    label: 'Mes ateliers',
    key: 'ateliers',
  },
  {
    label: 'Mes réalisations',
    key: 'realisations',
  },
  {
    label: 'Mes points de fidélité',
    key: 'points',
  },
  {
    label: 'Mes infos personnelles',
    key: 'infos',
  },
  {
    label: 'Mes newsletters',
    key: 'newsletters',
  },
  {
    label: 'Mes demandes',
    key: 'demandes',
  },
]
export default function DrawerElems({visible,onClose,elem, data }) {
const user = useSelector(state => state.user?.user?.Contact);
const mag = useSelector(state => state.magasin?.magasin);
const dispatch = useDispatch();
const [isLoading, setIsLoading] = useState(false);
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [txt, setTxt] = useState("");
let nomMag = mag ? mag.Nom : "WEB";
const basketItems = useSelector(state => state?.basket?.totalItems ? state?.basket?.totalItems : 0);
const favItems = useSelector(state => state?.favoris?.totalItems ? state?.favoris?.totalItems : 0);
const [elemInside, setElemInside] = useState(elem);
useEffect(() => {
  setElemInside(elem);
}, [elem]);

const deconnexion = () => {
  setIsLoading(true);
   axios.post("/users/deconnexion")   
   .then(res => {
      dispatch({type: "DELETE_USER"})
       setIsLoading(false);
       setIsErrorVisible(true);
       setTxt("Vous êtes déconnecté");
   })
   .catch(err => {
       console.log("err", err);
       setIsLoading(false);
       setTxt("Une erreur est survenue");
       setIsErrorVisible(true);
       dispatch({type: "DELETE_USER"})
   })
} 

  const renderContent = () => {
    switch (elemInside) {
      case 'geo':
        return (
          <Box>
            <LocalisationMobST className="mob"/>
          </Box>
        );
      case 'compte':
        return (
          <Box>
            {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Déconnexion"} onClick={() => setIsErrorVisible(false)}/>}
            {isLoading && <SpinnerST />}
            {user ? <><Title className="homemadeTitle">Bonjour {user?.Prenom} !</Title>
            <Menu
              mode="inline"
              style={{ height: "100%", borderRight: 0 }}
            >
              {items.map((item,i) => (
                <Menu.Item key={item.key}><a href={`/membre?tab=${i}`}></a>{item.label}</Menu.Item>
              ))}
            </Menu>
            <BoutonDeconnexiontST width={"100%"} lib={"Déconnexion"} onClick={deconnexion}/></> : <FormConnexionST/>}
          </Box>
        );
        case 'panier':
        return (
          <Box>
            {/* <Title>Mon panier</Title> */}
            <PanierAccueilST className={"mob"}/>
          </Box>
        );
        case 'favoris':
        return (
          <Box>
            {!user ? <TxtInfo className="column">
                <p>Votre projet est enregistré sur votre navigateur. Connectez-vous ou créer votre compte pour retrouver votre projet couture ultérieurement.</p>
                <LienNoirST lib={"Connectez-vous"} href="/membre"/>
                <LienNoirST lib={"Créez mon compte"} href="/membre"/>
              </TxtInfo> : null}
            <Title>Mon projet couture</Title>
            <FavorisST className="mob"/>
          </Box>
        );
        case 'menu':
        return (
          <Box className="menu">
            <DrawerMenu data={data}/>
          </Box>
        );
      default:
        return null;
    }
  }

return (
<StyledDrawer
placement="left"
closable={true}
onClose={onClose}
width={380}
open={visible}
footer={<Socials>
  <LinkStyledSocial href="https://www.facebook.com/SelfTissus"><FaFacebook/></LinkStyledSocial>
  <LinkStyledSocial href="https://www.pinterest.fr/selftissusfr/"><FaPinterest/></LinkStyledSocial>
  <LinkStyledSocial href="https://www.instagram.com/self_tissus/"><FaInstagram/></LinkStyledSocial>
  <LinkStyledSocial href="https://www.youtube.com/@selftissusfrance"><FaYoutube/></LinkStyledSocial>
  </Socials>}
// extra={<StyledImg src={Logo} style={{ width: " 120px" }} />}
extra={
  <SpaceStyled>
    <div>
      <StyledImg src={Logo} style={{ width: " 120px" }} />
      <Icons>
      <User><AiOutlineEnvironment  onClick={() => { setElemInside("geo"); }}/><Ville className="mob">{nomMag}</Ville></User>
        <User><AiOutlineUser  onClick={() => { setElemInside("compte"); }}/> {user && <Logged className="mob"><FaCheck/></Logged>}</User>
        <User><AiOutlineShoppingCart onClick={() => { setElemInside("panier"); }}/><Number className="mob2"><span>{basketItems}</span></Number></User>
        <User><AiOutlineHeart  onClick={() => { setElemInside("favoris"); }}/><Number className="mob2"><span>{favItems}</span></Number></User>
        <AiOutlineMenu onClick={() => { setElemInside("menu"); }}/>
        <AiOutlineClose onClick={onClose}/>
      </Icons>
    </div>
    <FormSearchST className={"mobileForm"}/>
  </SpaceStyled>
}
>

<SiderStyled
>
  {renderContent()}
</SiderStyled>

</StyledDrawer>
    );
  }