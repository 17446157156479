import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import { InputStyled,FormConnect } from "../DesignSystem/SelfTissus/Forms";
import { AiOutlineSearch } from "react-icons/ai";
import { BoutonVertSearch } from "../DesignSystem/Commons/Buttons";
import AdresseAutocomplete from "../AdresseAutocomplete/AdresseAutoComplete";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";

const Wrap = styled.div`
position: relative;
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;

const Link = styled.button`
border: none;
background: none;
color: ${props => props.theme.orangeST};
font-size: 20px;
text-decoration: underline;
text-align: left;
padding: 20px 0;
&:hover {
    color: ${props => props.theme.greenST};
}
`
const Title = styled.div`
text-transform: uppercase;
color: ${props => props.theme.orangeST};
font-size: 23px;
display: flex;  
gap: 10px;
justify-content: center;
align-items: center;
&.small {
    font-size: 20px;
    text-transform: initial;
}

`

const Nom = styled.div`
color: ${props => props.theme.greenST};
font-weight: bold;
margin-bottom: 10px;
font-size: 20px;
`

const Point = styled.div`
display: flex;
flex-direction: column;
width: 100%;
padding: 20px;
border: 2px solid ${props => props.theme.grey02ST};
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&:hover {
    border: 2px solid ${props => props.theme.orangeST};
    background-color: ${props => props.theme.grey05ST};
}
&.selected {
    border: 2px solid ${props => props.theme.orangeST};
    background-color: ${props => props.theme.grey05ST};
}
`;

const Selected = styled.button`
background: transparent;
border: none;
color: ${props => props.theme.greenST};
font-weight: bold;
margin-bottom: 10px;
font-size: 20px;
&>span {
    display: block;
}
&:hover {
    color: ${props => props.theme.orangeST};
}
`

export default function PointsRelaisListeST({pointsR, onPointSelect, isLoading, onSearch, onDateChange}) {

  const [selectedPoint, setSelectedPoint] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [dataSaved, setDataSaved] = useState({});
  const [hideList, setHideList] = useState(false);
  const dispatch = useDispatch();
  const handlePointClick = (point) => {
    setSelectedPoint(point);
    onPointSelect(point); // Send selected point to parent
    console.log("point",point);
    setHideList(true);
    dispatch({
      type: "SET_ADRESSE",
      payload: {
        IDAdresse: point.identifiant,
      }
    });
    dispatch({
      type: "SET_MAGASIN",
      payload: {
          ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
          Nom: "WEB"
      }
  });
    dispatch({
      type: "SET_RELAIS",
      payload: {
          ID: point.identifiant,
          Nom: point.nom
      }
  });
  };

  const handleChangeDate = (event) => {
    onDateChange(event.target.value);
  };

  useEffect(() => {
    onSearch(dataSaved); // Send dataSaved to parent whenever it changes
  }, [dataSaved, onSearch]);
  
    return (
        <Wrap>
          {/* {isLoading && <SpinnerInlineST />} */}
          {!hideList ? (<Wrap>
            <Title>Points relais</Title>
            <Title className="small">Changer la date de livraison ?</Title>
            <InputStyled type="date" onChange={handleChangeDate}/>
            
            {
                pointsR?.map((point) => {
                    return (
                        <Point key={point.identifiant} className={selectedPoint?.identifiant === point.identifiant ? "selected" : ""}>
                            <Nom>{point?.nom}</Nom>
                            <div>{point?.adresse1}</div>
                            <div>{point?.adresse2}</div>
                            <div>{point?.adresse3}</div>
                            <div>{point?.codePostal} {point?.localite}</div>
                            <span className="orange fs19">Horaires d'ouverture</span>
                            <div><b>Lundi :</b> {point?.horairesOuvertureLundi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureLundi}</div>
                            <div><b>Mardi :</b> {point?.horairesOuvertureMardi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureMardi}</div>
                            <div><b>Mercredi :</b> {point?.horairesOuvertureMercredi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureMercredi}</div>
                            <div><b>Jeudi :</b> {point?.horairesOuvertureJeudi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureJeudi}</div>
                            <div><b>Vendredi :</b> {point?.horairesOuvertureVendredi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureVendredi}</div>
                            <div><b>Samedi :</b> {point?.horairesOuvertureSamedi === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureSamedi}</div>
                            <div><b>Dimanche :</b> {point?.horairesOuvertureDimanche === "00:00-00:00 00:00-00:00" ? "Fermé" : point?.horairesOuvertureDimanche}</div>
                            <BoutonST onClick={() => handlePointClick(point)} lib="Choisir ce point relais" background={"#79b69f"} className={selectedPoint?.identifiant === point.identifiant ? "selected" : ""} />
                        </Point>
                    )
                })
            }
            <Title>Choisir un autre point relais</Title>
            <FormConnect className={"max"}>
              <AdresseAutocomplete setInputValue={setDataSaved} inputValue={dataSaved}  placeHolder="Adresse" onChange={({name, postcode, city  }) => setDataSaved({...dataSaved, name, postcode,city})}/>
            </FormConnect>
            </Wrap>) 
            : 
            <Selected onClick={() => setHideList(!hideList)}>
              <span>{selectedPoint?.nom}</span>
              <span>{selectedPoint?.adresse1}</span>
              <span>{selectedPoint?.adresse2}</span>
              <span>{selectedPoint?.adresse3}</span>
              <span>{selectedPoint?.codePostal} {selectedPoint?.localite}</span>
            </Selected>}
        </Wrap>
  );
}
