import React, { useState} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import FormLocalisationST from "../FormsST/FormLocalisation";
import PopErrorST from "../PopupsST/PopError";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";

const Box=styled.div`
position: relative;
width: 90%;
margin: 0 auto;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap:15px;
&.pop {
  overflow-y: scroll;
  height: 80vh;
}
@media screen and (max-width: 768px) {
  padding: 0;
  width: 100%;
}
`

const Buts = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 20px;
margin-bottom: 30px;
`


export default function LocalisationST({onClick, mode}) {
    
    const dispatch = useDispatch();
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState("");
    const [open, setOpen] = useState(false);

    const setMagWeb = () => {
      dispatch({
        type: "SET_MAGASIN",
        payload: {
            ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
            Nom: "WEB"
        }
      });
      dispatch({
        type: "SET_MODELIVR",
        payload: {
          modeLivraison: 1
        }
      });
      setTxt("Vous avez choisi la livraison à domicile");
      setIsErrorVisible(true);
    }
    
    
  
    return (
      <>
        <Box className={mode}>
        {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
          <h2 className="center homemadeTitle initial green">Astuce</h2>
          <Buts>
            <BoutonST background={"#79b69f"} lib={"CLICK & COLLECT"} onClick={() => {setOpen(true)}}/>
            <BoutonST onClick={() => {setMagWeb(); setOpen(false)}} background={"#f05b32"} lib={"Livraison à domicile"}/>
          </Buts>
          {open && <FormLocalisationST onClick={onClick} mode={mode}/>}
        </Box>
       
      </>
    );
  }
