import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import EspaceST from "../DesignSystem/Espace";
import FormSearchST from "../FormsST/FormSearchSelf";
import LinkNoirST from "../DesignSystem/SelfTissus/LinkNoir";
import LinkST from "../DesignSystem/SelfTissus/Link";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import picDefault from "../../assets/images/photo.jpg";

const Wrap = styled.div`
    padding: 30px 100px;
    @media (max-width: 768px) {
        padding: 30px 20px;
    }
`
const WrapInside = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
    @media (max-width: 768px) {
    }
`
const Result = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 20px;
&> img {
    max-width: 150px;
    box-shadow: 0 0 5px 0 ${props => props.theme.jaune01ST};
}
`
const Txt = styled.div`
margin: 30px 0 0 0;
font-size: 17px;
`

export default function RechercheResultats() {
    const location = useLocation();
    let data = location?.state?.data?.Recherches;
    const inputValue = location?.state?.inputValue;
    const [num, setNum] = useState(0);

    useEffect(() => {
        let count;
        count = data?.length;
        setNum(count);
      }, [data]);

        return(
        <>
        <Wrap>
            <WrapInside>
                <FormSearchST placeholder="Recherche"/>
                {inputValue && (
                    <h3>
                    {
                    num === 0 || num === undefined 
                        ? `Désolé, aucune correspondance n'a été trouvée pour votre recherche '${inputValue}' dans nos contenus.`
                        : `${num} résultats trouvés sur ce site pour votre recherche '${inputValue}'.`
                    } 
                    </h3>
                    )}
            </WrapInside>
        <WrapInside>
        {
            data?.sort((a, b) => a.Trie - b.Trie)
                .map((item, index) => (
                <>
                    <Result key={index}>
                        {/* <img src={item?.Meta?.Meta?.URLImage || picDefault} */}
                        <img src={picDefault}
                        alt={item?.Meta?.Meta?.Titre}/>
                        <LienNoirST href={item?.slug} lib={item?.Lib}/>
                        {item?.Description && <Txt>{item?.Description}</Txt>}
                    </Result>
                    <EspaceST color={props => props.theme.greenST}/>
                </>
                ))
            }
            </WrapInside>
        </Wrap>
        </>
    )
}

