import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Card } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { InputStyled, FormGroupStyled, LabelStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import picDefault from "../../assets/images/photo.jpg";
import  {FormGroup ,Label} from "reactstrap";

const CardGridStyled = styled(Card.Grid)`
&.nom {
    width: 60%;
    &.mob {
       width: 100%; 
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}

&.quantite{
    width: 25%;
    &.mob {
       width: 60%; 
    }
    @media only screen and (max-width: 786px) {
        width: 60%;
    }
}
&.prix{
    width: 15%;
    &.mob {
       width: 40%; 
    }
    @media only screen and (max-width: 786px) {
        width: 40%;
    }
}
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
}
`;

const PanierWrap = styled.div`
width: 100%;
&>.ant-card {
   background: #ffffff75;
   padding: 20px;
}
&>.ant-card > div.ant-card-body > .ant-card-grid {
    display: flex;
    align-items: center;
}
&.mob {
    &>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    display: none;
}
}
&>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    @media only screen and (max-width: 786px) {
        display: none;
    }
}
`;

const Produit = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 30px;`
const Img = styled.img`
width: 100px;
height: 100px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`
const Txt = styled.div`
display: flex;
flex-direction: column;
align-content: flex-start;
gap: 20px;
flex-wrap: wrap;
&>span {
    font-size: 20px; 
    color: ${props => props.theme.grey02ST};
    @media only screen and (max-width: 786px) {
        padding: 10px;
    }
}
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
@media only screen and (max-width: 786px) {
    text-align: center;
}
`

const Quantity = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: space-between;
&>.trash {
    font-size: 20px;
    color: ${props => props.theme.grey02ST};
    &:hover {
        color: ${props => props.theme.redST};
        cursor: pointer;
    }
}
` 

const Promo = styled.div`
display: flex;
align-items: center;
gap: 20px;
justify-content: flex-end;
width: 100%;
margin-top: 20px;
`

const Livr = styled.div`
width: auto;
border: 2px solid #f0f0f0;
position: relative;
display: flex;
gap: 5px;
padding:  20px;
margin-top: 20px;
justify-content: flex-end;
margin-left: auto;
`

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`
const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;


const Type = styled.p`
color: ${props => props.theme.grey02ST};
font-size: 20px;
`;



export default function PanierRecapST({className, atelierOnly, isTermsAccepted, handleCheckboxChange,}) {
const basket = useSelector(state => state.basket);
const relais = useSelector(state => state.relais?.relais);
const mag = useSelector(state => state.magasin?.magasin);
const adresse = useSelector(state => state.adresse?.adresse);
const modeL = useSelector(state => state.modeLivr?.modeLivraison);
const [quantities, setQuantities] = useState(basket?.items?.map(item => item.quantity));
const [isLoading, setIsLoading] = useState(false);
const [txt, setTxt] = useState("");
const user = useSelector(state => state.user?.user?.Contact); 

console.log("atelierOnly", atelierOnly);

const totalPrice = basket?.items?.reduce((total, item, index) => {
    const price = item.Prix ?? item?.PUTTC;
        return total + (quantities[index] * price);
    }, 0).toFixed(2);

    return (
            <PanierWrap className={className}>
                <h2 className="center homemadeTitle initial green">Récapitulatif de votre commande</h2>
                <Card bordered={false}>
                <Card.Grid className="pc" hoverable={false} style={{width:"60%"}}>
                <p className="mb0 uppercase">Produit</p>
                </Card.Grid>

                <Card.Grid className="pc"  hoverable={false} style={{width:"25%"}}>
                <p className="mb0 uppercase">Quantité</p>
                </Card.Grid>

                <Card.Grid className="pc" hoverable={false} style={{width:"15%",textAlign:"right"}}>
                <p className="mb0 uppercase right">Total</p>
                </Card.Grid>

                {
                    basket.items.map((item, i) => (
                        <>
                            {item?.mode === "article" ? (
                                <>
                                    <CardGridStyled className={`nom ${className}`}>
                                        <Produit>
                                            <Img src={item?.Photos[0]?.image?.url ? item?.Photos[0]?.image?.url : picDefault} alt={item?.Photos[0]?.image?.alt || "image du produit"} onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = picDefault;
                                            }}/>
                                            <Txt>
                                                <LienNoirST lib={item?.libArticle} href={item?.slug || "#"}  margin={"0"}/>
                                                <span>€{item?.Prix}</span>
                                            </Txt>
                                        </Produit>
                                    </CardGridStyled>

                                    <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                        <Quantity>
                                            <FormGroupStyled>
                                                <InputStyled
                                                    id={`quantity-${i}`}
                                                    name="number"
                                                    type="number"
                                                    value={quantities[i]}
                                                    readonly
                                                    disabled
                                                />
                                            </FormGroupStyled>
                                        </Quantity>
                                    </CardGridStyled>

                                    <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                        <Prix className="fs20">€{(quantities[i] * (item?.Prix)).toFixed(2)}</Prix>
                                    </CardGridStyled>
                                </>
                            ) : item?.mode === "atelier" ? (
                                <>
                                <CardGridStyled className={`nom ${className}`}>
                                    <Produit>
                                        <Img src={item?.img?.url ? "https://selftissus.yogan.pro"+item?.img?.url : picDefault} alt={item?.img?.alt || "image de l'atelier"} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = picDefault;
                                            }}/>
                                        <Txt>
                                            <LienNoirST lib={item?.lib} href={item?.slug} margin={"0"}/>
                                            <span>€{item?.PUTTC}</span>
                                        </Txt>
                                    </Produit>
                                </CardGridStyled>

                                <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                    <Quantity>
                                        <FormGroupStyled>
                                            <InputStyled
                                                id={`quantity-${i}`}
                                                name="number"
                                                type="number"
                                                value={quantities[i]}
                                                readonly
                                                disabled
                                            />
                                        </FormGroupStyled>
                                    </Quantity>
                                </CardGridStyled>

                                <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                    <Prix className="fs20">€{(quantities[i] * (item?.PUTTC)).toFixed(2)}</Prix>
                                </CardGridStyled>
                                </>
                            ) : null}
                        </>
                    ))
                }
                
                <Promo>
                <FormGroupStyled floating>
                    <InputStyled
                        id="exampleNumber"
                        name="number"
                        placeholder="Saissir un code"
                        type="text"
                    />
                    <LabelStyled for="exampleNumber">Code promo ?</LabelStyled>
                    </FormGroupStyled>
                    <BoutonST lib={"Appliquer"}
                            background={props => props.theme.orangeST}
                            width={"300px"}
                            margin={"0"}
                            />
                </Promo>
                
                <Promo>
                <FormGroupStyled floating>
                    <InputStyled
                        id="exampleNumber"
                        name="number"
                        placeholder="Saissir un code"
                        type="text"
                    />
                    <LabelStyled for="exampleNumber">Carte cadeau ?</LabelStyled>
                    </FormGroupStyled>
                    <BoutonST lib={"Appliquer"}
                            background={props => props.theme.orangeST}
                            width={"300px"}
                            margin={"0"}
                            />
                </Promo>
                {!atelierOnly &&<Livr>
                    {/* <div><p className='green bold fs20'>LIVRAISON CHOISIE</p>
                    {relais ? <div><Type>Point Relais</Type> <span>{relais?.Nom}</span> </div>
                    : adresse ? <div><Type>Livraison à domicile</Type></div>
                    : (mag && mag?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5") ?
                    <div><Type>Click & Collect</Type>  <span>{mag?.Nom}</span></div>
                    : <span>Veuillez choisir un mode de livraison</span>}

                    </div> */}
                    <div><p className='green bold fs20'>LIVRAISON CHOISIE</p>
                    {modeL === 2 ? <span>Click & Collect</span>
                    : modeL === 3 ? <span>Point Relais</span>
                    : modeL === 1 ? <span>Livraison à domicile</span>
                    : <span>Veuillez choisir un mode de livraison</span>}


                    </div>
                </Livr>}
                
                <Total className={className}>
                    <Prix className="fs16">Total : &nbsp;<span> €{totalPrice} EUR</span></Prix>
                    <Prix className="fs16">Taxe incluse, frais d’expédition et réductions calculés à l’étape du paiement</Prix>
                </Total>
                <div>
                <FormGroup check>
                    <InputStyled type="checkbox" 
                    checked={isTermsAccepted} onChange={handleCheckboxChange}
                    />
                    <Label check>
                    J'accepte les <a href="/informations-legales/cgv">conditions générales de vente</a>
                    </Label>
                </FormGroup>
                {!isTermsAccepted &&<Message>
                    <p className='white bold'>Vous devez accepter les conditions générales de vente pour continuer</p>
                </Message>}
                </div>

            </Card>

            
               
            </PanierWrap>
  );
}
