import React, { useState, lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "@reach/router";
import axios from "axios";
import loadable from "@loadable/component";
import TitreTxtBtnST from "../components/BlocsST/TitreTextBtn";
import VideoST from "../components/Video/VideoST";
import SliderProduitsST from "../components/SlidersST/SliderProduits";
import BlogListeST from "../components/Blog/BlogListeSF";
import BandeauTutosST from "../components/SectionTutos/BandeauTutos";
import { Container } from "../components/DesignSystem/SelfTissus/ContainersST";
import SocialsBigST from "../components/Socials/SocialsBig";
import TitreTextST from "../components/ContenusST/TitreText";
import TitreH2TextST from "../components/ContenusST/TitreH2Text";
import PresentationTitreST from "../components/ContenusST/PresentationTitre";
import TextST from "../components/ContenusST/Text";
import FormLevelST from "../components/FormsST/FormLevel";
import H1_ST from "../components/ContenusST/H1";
import H2_ST from "../components/ContenusST/H2";
import H2LinesST from "../components/ContenusST/H2Lines";
import SliderYouTubeST from "../components/SlidersST/SliderYouTube";
import InteractionsST from "../components/BandeauInteractions/BandeauInteractions";
import SliderProduitsAdminST from "../components/SlidersST/SliderProduitsAdmin";
import ArticleST from "../components/Article/Article";
import SliderRealisationsST from "../components/SlidersST/SliderRealisations";
import NavBlogsST from "../components/NavBar/NavBlogs";
import AteliersListeST from "../components/Atelier/AteliersListe";
import AtelierFicheST from "../components/Atelier/AtelierFiche";
import ArticlesListeST from "../components/ArticlesListe/ArticlesListe";
import NotFoundST from "../components/404/NotFound";
import FormContactST from "../components/FormsST/FormContact";
import FaqST from "../components/FAQ/FaqListe";
import CardsST from "../components/ContenusST/Cards";
import ImgGaucheTxtST from "../components/ContenusST/ImgGaucheTxt";
import BlocsImgTxtST from "../components/ContenusST/BlocsImgText";
import MapST from "../components/Magasins/MagasinsMap";
import HeroHomeST from "../components/Hero/HeroSlider_HomeST";
import InfosListeST from "../components/Livraison/InfosListe";
import ImageST from "../components/Images/Image";
import ImageLienST from "../components/Images/ImageLien";
import ImgDroitTxtST from "../components/ContenusST/ImgDroitTxt";
import BoutonNoirLnkST from "../components/DesignSystem/SelfTissus/BoutonNoirLnk";
import CookiesBoxST from "../components/Cookies/CookiesBox";
import EspaceST from "../components/DesignSystem/Espace";
import { PopUpBottom,PopUp, Wrap,ButFermer } from "../components/DesignSystem/PopUpST";
import FormRecommandST from "../components/FormsST/FormRecommand";
import FormNewsletterST from "../components/FormsST/FormNewsletter";
import MagasinFicheST from "../components/Magasins/MagasinFiche";
import CategorieIntroST from "../components/Categorie/CategorieIntro";
import FormConnexionST from "../components/FormsST/FormConnexion";
import EspaceMembreST from "../components/Membre/EspaceMembre";
import LocalisationST from "../components/Localisation/Localisation";
import FranchiseST from "../components/Franchise/Franchise";
import MagasinsListeST from "../components/Magasins/MagasinsListe";
import PageLoading from "../components/DesignSystem/PageLoading";
import RealisationsListeST from "../components/Realisations/RealisationsListe";
import TutosListeST from "../components/Realisations/TutosListe";
import Footer1ST from "../components/FooterST/Footer1_ST";
import Footer2ST from "../components/FooterST/Footer2_ST";
import Footer3ST from "../components/FooterST/Footer3_ST";
import Footer4ST from "../components/FooterST/Footer4_ST";
import ProduitsListeST from "../components/Produits/ProduitsListe";
import SpinnerST from "../components/DesignSystem/SpinnersST/Spinner";
import PanierPaiementValideST from "../components/Panier/PanierPaiementValide";
import PanierPaiementInvalideST from "../components/Panier/PanierPaiementInvalide";

export default function Dispatcher() {

    const location = useLocation();
    const [dataPageComp, setDataPageComp] = useState([]);
    const [dataPage, setDataPage] = useState([]);
    const [dataArticles, setDataArticles] = useState([]);
    const [dataAteliers, setDataAteliers] = useState([]);
    const [dataSousCategories, setDataSousCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataBandeau1, setDataBandeau1] = useState([]);
    const [showRecommand, setShowRecommand] = useState(true);
    const [showNL, setShowNL] = useState(true);
    const dispatch = useDispatch();
    const mag = useSelector(state => state.magasin.magasin);
 console.log("location.pathname", location.pathname==="/paiement-valide");
    if (mag === null) {
        dispatch({ type: "SET_MAGASIN", payload: { ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5", Nom: "WEB" } });
    }
    const user = useSelector(state => state.user?.user?.Contact);


    useEffect(() => {
        if(location.pathname==="/paiement-valide"){
            dispatch({
                type: "DELETE_ADRESSE",
              });
              dispatch({
                type: "DELETE_BASKET",
              });
              dispatch({
                type: "DELETE_MODELIVR",
              });
              dispatch({
                type: "DELETE_RELAIS",
              });
              dispatch({
                type: "SET_MAGASIN",
                payload: {
                    ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
                    Nom: "WEB"
                }
              });
            };
    }, [location.pathname]);

    useEffect(() => {
        const subscribedNL = localStorage.getItem('subscribedNL');
        const hasSubscribedNL = subscribedNL === 'true';
        const hasClosedNL = localStorage.getItem('closedNL');
        const closedNLTimestamp = localStorage.getItem('closedNLTimestamp');
        const oneHour = 60 * 60 * 1000;
        const hours = oneHour * 5;

        if (hasSubscribedNL) {
          setShowNL(false);
        }

        if (user?.Newsletter) {setShowNL(false);}

        if (hasClosedNL) {
            if (closedNLTimestamp) {
                const currentTime = new Date().getTime();
                const timeElapsed = currentTime - closedNLTimestamp;
                if (timeElapsed > hours) {
                    setShowNL(true);
                    localStorage.removeItem('closedNLTimestamp');
                } else {
                    setShowNL(false);
                }
            }
        }
      }, []);

      const handleCloseNL = () => {
        const currentTime = new Date().getTime();
        localStorage.setItem('closedNLTimestamp', currentTime);
        localStorage.setItem('closedNL', true);
        setShowNL(false);
    };

       
    
    useEffect(() => {
        const subscribedRecommand = localStorage.getItem('subscribedRecommand');
        const hasSubscribedRecommand = subscribedRecommand === 'true';
        const closedRecommandTimestamp = localStorage.getItem('closedRecommandTimestamp');
        const hasClosedRecommand = localStorage.getItem('closedRecommand');
        const oneHour = 60 * 60 * 1000;
        const hours = oneHour * 5;

        if (hasSubscribedRecommand) {
          setShowRecommand(false);
        }
        if (hasClosedRecommand) {
            if (closedRecommandTimestamp) {
                const currentTime = new Date().getTime();
                const timeElapsed = currentTime - closedRecommandTimestamp
                if (timeElapsed > hours) {
                    setShowRecommand(true);
                    localStorage.removeItem('closedRecommandTimestamp');
                } else {
                    setShowRecommand(false);
                }
            }
        }
      }, []);

      const handleCloseRecommand = () => {
        const currentTimeR = new Date().getTime();
        localStorage.setItem('closedRecommandTimestamp', currentTimeR);
        localStorage.setItem('closedRecommand', true);
        setShowRecommand(false);
    };

    useEffect(() => {
        setIsLoading(true);
    
             axios.get(
              `/dataslug?{"CleCtrl":"TaTaYoYo","act":"DataSlug", "Slug":"${location.pathname}"}`
            ).then(res => {
            // console.log("res.data", res.data);
            setDataPage(res.data ? res.data : []);
            setDataArticles(res.data?.CategorieWeb && res.data?.CategorieWeb?.Articles ? res.data?.CategorieWeb?.Articles : []);
            setDataAteliers(res.data?.CategorieWeb && res.data?.CategorieWeb?.Ateliers ? res.data?.CategorieWeb?.Ateliers : []);
            setDataSousCategories(res.data?.CategorieWeb && res.data?.CategorieWeb?.SousCategorieWeb ? res.data?.CategorieWeb?.SousCategorieWeb : []);
            setDataPageComp(res?.data?.Composants ? res?.data?.Composants : []);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            // if (error?.response?.status === 301) {
            //   navigate(error?.response?.data);
            // }
        }).finally(() => {
        //   setIsLoading(false);
        } );
  
    }, [location.pathname]);

    useEffect(() => {
        setIsLoading(true);
        axios.post(`/users/bandeau`, { Limite: "", OFFSET: "", NumBandeau:1})
          .then((response) => {
            setDataBandeau1(response?.data);
            // setIsLoading(false);
          })
          .catch((err) => {
            console.log("err", err);
            // setIsLoading(false);
          });
      }, []);

    // useEffect(() => {
    //     setIsLoading(true);
    //     axios.post(`/users/filsariane`)
    //       .then((response) => {
    //         console.log("responseFA", response);
    //         // setIsLoading(false);
    //       })
    //       .catch((err) => {
    //         console.log("err", err);
    //         // setIsLoading(false);
    //       });
    //   }, []);

    console.log("dataPage", dataPage);

    return (
        <div>
            
            <Container>
                {isLoading ? ( <PageLoading />) : (
                dataPageComp &&
                dataPageComp
                .sort((a, b) => a.Tri - b.Tri)
                .map((component, index) => {
                    switch (component.ModeleType) {

                        case "":
                        return null;

                        case "NotFoundST":
                        return (
                            <NotFoundST/>
                        );
                        
                        case "Hero_HomeST":
                        return (
                            dataBandeau1?.Bandeau && <HeroHomeST key={component.ID} data={dataBandeau1}/>
                        );
                        
                        case "TitreTxtBtnST":
                        return (
                            <TitreTxtBtnST key={component.ID} data={component}/>
                        );
                        
                        case "VideoST":
                        return (
                            <VideoST key={component.ID}  data={component}/>
                        );
                        
                        case "SliderProduitsST":
                        return (
                            // <Suspense fallback={<PageLoading />}>
                                <SliderProduitsST key={component.ID} data={component}/>
                        );
                        
                        case "SliderProduitsAdminST":
                        return (
                            <SliderProduitsAdminST key={component.ID} data={component}/>
                        );
                        
                        case "BlogListeST":
                        return (
                            <BlogListeST key={component.ID} data={component}/>
                        );
                        
                        case "NavBlogsST":
                        return (
                            <NavBlogsST key={component.ID} data={component}/>
                        );
                        
                        case "BandeauTutosST":
                        return (
                            <BandeauTutosST key={component.ID} data={component}/>
                        );
                        
                        case "TitreTextST":
                        return (
                            <TitreTextST key={component.ID} data={component}/>
                        );
                        
                        case "TextST":
                        return (
                            <TextST key={component.ID} txt={component?.Corps}/>
                        );
                        
                        case "TitreH2TextST":
                        return (
                            <TitreH2TextST key={component.ID} data={component}/>
                        );
                        
                        case "PresentationTitreST":
                        return (
                            <PresentationTitreST key={component.ID} titre={component?.Titre}/>
                        );
                        
                        case "SocialsBigST":
                        return (
                            <SocialsBigST key={component.ID} data={component}/>
                        );
                        
                        case "InteractionsST":
                        return (
                            <InteractionsST key={component.ID} data={component}/>
                        );
                        
                        case "FormLevelST":
                        return (
                            <FormLevelST key={component.ID} data={component?.Data} titre={component?.Titre}/>
                        );
                        
                        case "H1_ST":
                        return (
                            <H1_ST key={component.ID} data={component}/>
                        );
                        
                        case "H2_ST":
                        return (
                            <H2_ST key={component.ID} data={component}/>
                        );
                        
                        case "H2LinesST":
                        return (
                            <H2LinesST key={component.ID} data={component}/>
                        );

                        case "SliderYouTubeST":
                        return (
                            <SliderYouTubeST key={component.ID} data={component}/>
                        );

                        //Fiche produit
                        case "ProduitST":
                        return (
                            <ArticleST key={component.ID} data={dataPage}/>
                        );

                        case "SliderRealisationsST":
                        return (
                            <SliderRealisationsST key={component.ID} data={component}/>
                        );

                        case "RealisationsListeST":
                        return (
                            <RealisationsListeST key={component.ID} data={component}/>
                        );
                        case "TutosListeST":
                        return (
                            <TutosListeST key={component.ID} data={component}/>
                        );

                        case "AteliersListeST":
                        return (
                            <AteliersListeST key={component.ID} data={component}/>
                        );

                        case "AtelierFicheST":
                        return (
                            <AtelierFicheST key={component.ID} data={dataPage?.Atelier} cats={dataSousCategories}/>
                        );

                        case "ProduitsListeST":
                        return (
                            // <ArticlesListeST key={component.ID} data={dataArticles} cats={dataSousCategories}/>
                            // <Suspense fallback={null}>
                            <ProduitsListeST key={component.ID} dataArticles={dataArticles} dataAteliers={dataAteliers} cats={dataSousCategories}/>
                            // </Suspense>
                        );

                        case "FormContactST":
                        return (
                            <FormContactST key={component.ID} data={component}/>
                        );

                        case "FaqST":
                        return (
                            <FaqST key={component.ID} data={component}/>
                        );

                        case "CardsST":
                        return (
                            <CardsST key={component.ID} data={component?.Data} titre={component?.Titre}/>
                        );

                        case "ImgGaucheTxtST":
                        return (
                            <ImgGaucheTxtST key={component.ID} data={component}/>
                        );
                        case "ImgDroitTxtST":
                        return (
                            <ImgDroitTxtST key={component.ID} data={component}/>
                        );

                        case "BlocsImgTxtST":
                        return (
                            <BlocsImgTxtST key={component.ID} data={component?.Data}/>
                        );

                        case "MapST":
                        return (
                            <MapST key={component.ID}/>
                        );

                        case "InfosListeST":
                        return (
                            <InfosListeST key={component.ID} data={component}/>
                        );

                        case "ImageLienST":
                        return (
                            <ImageLienST key={component.ID} data={component}/>
                        );

                        case "ImageST":
                        return (
                            <ImageST key={component.ID} data={component}/>
                        );

                        case "BoutonNoirLnkST":
                        return (
                            <BoutonNoirLnkST key={component.ID} href={component?.BTNUrl} lib={component?.BTNLib} target={component?.BTNMode ? "_blank" : "_self"}/>
                        );

                        case "EspaceST":
                        return (
                            <EspaceST key={component.ID}/>
                        );

                        case "CategorieIntroST":
                        return (
                            <CategorieIntroST key={component.ID} data={dataPage?.CategorieWeb}/>
                        );

                        case "MagasinFicheST":
                        return (
                            <MagasinFicheST key={component.ID} data={dataPage?.Exploitant}/>
                        );

                        case "LocalisationST":
                        return (
                            <LocalisationST key={component.ID}/>
                        );

                        case "FranchiseST":
                        return (
                            <FranchiseST key={component.ID} data={component}/>
                        );

                        case "MagasinsListeST":
                        return (
                            <MagasinsListeST key={component.ID}/>
                        );

                        case "FormConnexionST":
                        return (
                            <FormConnexionST key={component.ID}/>
                        );

                        case "PanierPaiementValideST":
                        return (
                            <PanierPaiementValideST key={component.ID} data={component}/>
                        );

                        case "PanierPaiementInvalideST":
                        return (
                            <PanierPaiementInvalideST key={component.ID} data={component}/>
                        );
                        
                        default:
                            return null;
                        }
                    })
                    )}
                    </Container>
                <CookiesBoxST />
                {/* {showRecommand && ReactDOM.createPortal(<PopUpBottom>
                <ButFermer onClick={() => {
                    setShowRecommand(false);
                    handleCloseRecommand();
                }}>X</ButFermer>
                <Wrap>
                    <FormRecommandST closeR={() => setShowRecommand(false)} />
                </Wrap>
                </PopUpBottom>,
                document.body)
                } */}
            
                {showNL && ReactDOM.createPortal(<PopUp position={"fixed"}>
                    <ButFermer onClick={() => { 
                    setShowNL(false);
                    handleCloseNL(); 
                    }}>X</ButFermer>
                <Wrap>
                    <FormNewsletterST close={() => setShowNL(false)} />
                </Wrap>
                </PopUp>,
                document.body)
                }
                {/* <Suspense fallback={null}> */}
                <Footer1ST />
                <Footer2ST />
                <Footer3ST/>
                <Footer4ST/>
                {/* </Suspense> */}
          
          </div>
          );
        }
        
