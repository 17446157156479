import React from "react";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { RotatingTriangles } from 'react-loader-spinner'

const DivLoading = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
background: #ffffffeb;
border-radius: 50%;
padding: 10px;
margin: auto;
z-index: 99999;
`;


export default function SpinnerInlineMiniST() {
    const theme = useTheme();
    const color1 = theme.jaune01ST;
    const color3 = theme.redST;
    const color4 = theme.greenST;
  let [loading, setLoading] = useState(true);

  return (
  <DivLoading className="sweet-loading">
    <RotatingTriangles
    visible={true}
    height="35"
    width="35"
    colors={[color1, color3, color4]}
    />
    </DivLoading>
  );
}