import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/livraison-rapide.webp";
import img2 from "../../assets/images/livraison-offerte.webp";
import img3 from "../../assets/images/a-votre-ecoute.webp";
import img4 from "../../assets/images/paiement-securise.webp";



const StyledDiv = styled.div`
background: ${props => props.theme.beige02ST};
padding: 50px 0;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
font-family: "Assistant", sans-serif;
  @media screen and (max-width: 678px) {
    flex-direction: column;
  } 
`;
const Box = styled.div`
display: flex;
align-items: center;
flex-direction: column;
justify-content: flex-start;
width: calc(100% / 4);
gap: 10px;
letter-spacing: 0.04em;
padding: 0 5px;
@media screen and (max-width: 678px) {
    width: 100%;
  } 
`;
const Icon = styled.div`
    width: 60px;
    margin: 5px 0 5px 0;
`;
const Title = styled.div`
    text-align: center;
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 160%;
    font-size: 20px;
    color: #000000;
    `;
const Txt = styled.div`
text-align: center;
line-height: 160%;
font-size: 14px;
color: #4b4b4b;
`;



export default function Footer2ST(props) {

  return (
      <StyledDiv>
        <Box>
            <Icon><img src={img1} alt="icon temps de livraison"/></Icon>
            <Title>Livraison rapide</Title>
            <Txt>Expédition le jour même ou le lendemain
            (jour ouvré) de votre commande</Txt>
        </Box>
        <Box>
            <Icon><img src={img2} alt="icon livraison"/></Icon>
            <Title>Livraison offerte</Title>
            <Txt>à partir de 50€ d'achats
            en France métropolitaine</Txt>
        </Box>
        <Box>
            <Icon><a href="/nos-services/nous-contacter"><img src={img3} alt="icon téléphone"/></a></Icon>
            <Title>A votre écoute</Title>
            <Txt>Une question ? Contactez notre service client. Appelez nos expertes couture au 02.98.90.99.99
            de 9h à 13h et de 14h à 17H</Txt>
        </Box>
        <Box>
            <Icon><img src={img4} alt="icon paiements"/></Icon>
            <Title>Paiements sécurisés</Title>
            <Txt>par carte bancaire ou Paypal,
            paiement en 3x sans frais à partir de 150€</Txt>
        </Box>


       
      </StyledDiv>
  );
}
