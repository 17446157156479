import React, {useEffect,useRef,useState} from "react";
import axios from "axios";
import styled from "styled-components";
import BoutonFlecheST from "../DesignSystem/SelfTissus/BoutonFleche";
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import {Section} from "../DesignSystem/SelfTissus/ContainersST";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import picDefault from "../../assets/images/photo.jpg";


const Keen = styled.div`
background: ${(props) => props.theme.grey08ST};
min-height: 380px !important;
max-height: 380px !important;
padding: 0;
`;

const Bloc = styled.a`
display: block;
text-decoration: none;
overflow: hidden;
height: 100%;
width: 100%;
height: 380px;
max-height: 380px !important;
&:hover {
  text-decoration: none;
}
  `;

const ImgDiv = styled.div`
background-image: url(${(props) => props.src});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
width: 100%;
height: 100%;
`;

const Link = styled.div`
position: absolute;
bottom: 0;
width: 100%;
background-color: #fff;
`;

const Prices = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 18px;
gap: 20px;
padding: 0 20px;
`
const Old = styled.span`
text-decoration: line-through solid 2px ${(props) => props.theme.redST};
`
const New = styled.span`
font-weight: 700;
`

export default function SliderProduitsST({data}) {
  console.log('data:', data);
  const type = data && data.Data && data.Data[0]?.JSON?.Type ? data.Data[0]?.JSON?.Type : "";
  const idCat = data && data.Data && data.Data[0]?.JSON?.IDCategorieWeb ? data.Data[0]?.JSON?.IDCategorieWeb : "";
  const [dataProduits, setDataProduits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: {
      perView: 4,
      spacing: 25,
    },
    breakpoints: {
      "(max-width: 1360px)": {
        slides: { perView: 3, spacing: 15 },
      },
      "(max-width: 992px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(max-width: 768px)": {
        slides: { perView: 2, spacing: 25 },
      },
      "(max-width: 670px)": {
        slides: { perView: 1, spacing: 0 },
      },
    },
  }, [
    (slider) => {
      let timeout;
      let mouseOver = false;

      function clearNextTimeout() {
        clearTimeout(timeout);
      }

      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 2000);
      }

      slider.on("created", () => {
        slider.container.addEventListener("mouseover", () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener("mouseout", () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });

      slider.on("dragStarted", clearNextTimeout);
      slider.on("animationEnded", nextTimeout);
      slider.on("updated", nextTimeout);
    },
  ]);


  useEffect(() => {
    if (type === "" && idCat === "") {
      console.log("Type and IDCategorieWeb are empty. Skipping axios request.");
      return; 
    }
    setIsLoading(true);
    axios.post(`/users/produits`,{Type : type, IDCategorieWeb : idCat, Recherche : ""})
    .then((response) => {
      setDataProduits(response?.data?.Articles);
      setIsLoading(false);
      setLoaded(true);
    })
    .catch((err) => {
      console.log("err", err);
    });
}, [type, idCat]);

console.log('dataProduits:', dataProduits);

  return (
   <Section className="navigation-wrapper noPadding">
    {data?.Titre && <h2>{data?.Titre }</h2>}
    {data?.Corps  && <p>{data?.Corps }</p>}
    {isLoading &&<SpinnerInlineST/>}
    <>
        {dataProduits?.length > 0 ? (
            <div ref={sliderRef} className="keen-slider">
                {dataProduits &&
                    dataProduits.map((item, i) => {
                        let imageUrl =
                            item &&
                            item?.Articles &&
                            item?.Articles[0]?.Photos
                                ? item?.Articles[0]?.Photos?.[0]?.image?.url
                                : "";
                        return (
                            <Keen key={i} className="keen-slider__slide">
                                <Bloc href={item?.slug}>
                                    {item?.Photos && (
                                        <ImgDiv
                                            src={item?.Photos[0]?.image?.url || picDefault}
                                        />
                                    )}
                                </Bloc>
                                {item?.libArticle && (
                                    <Link>
                                        <BoutonFlecheST
                                            lib={item?.libArticle}
                                            href={item?.slug}
                                        />
                                        {/* {item?.oldPrice && (
                                            <Prices>
                                                <Old>{item?.Prix}</Old>
                                                <New>{item?.oldPrice}</New>
                                            </Prices>
                                        )} */}
                                    </Link>
                                )}
                            </Keen>
                        );
                    })}
            </div>
        ) : null}
        {loaded && <div className="dots">
        <button title="Précédent" className={"dot"}
        onClick={(e) =>
          e.stopPropagation() || instanceRef?.current?.prev()
        }
        // disabled={currentSlide === 0}
      />
      <button title="Active"  className={"dot active"}
      ></button>
      <button title="Suivant" className={"dot"}
        onClick={(e) =>
          e.stopPropagation() || instanceRef?.current?.next()
        }
        disabled={
          currentSlide ===
          instanceRef?.current?.track?.details?.slides?.length - 1
        }
      />
    </div>}
    </>
   </Section>
  );
}