import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { List } from 'antd';
import {Wrap} from "./ElementsMembre";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import {StyledFade} from "../DesignSystem/SelfTissus/ContainersST";
import ReactHtmlParser from 'react-html-parser';
import BoutonST from "../DesignSystem/SelfTissus/Bouton";


const ListItemStyled = styled(List.Item)`
width: 100%;
border: 1px solid ${(props) => props.theme.grey01ST} !important;
border-radius: 8px;
padding: 20px;
margin: 0 0 20px 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
&:nth-child(odd) {
    background-color: ${(props) => props.theme.grey08ST};
  }
&>.ant-list-item-extra {
  display: flex;
  align-items: end;
  margin-inline-start: 0 !important;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap !important;
  &>.ant-list-item-extra {
    margin: 0 !important;
    &> div > a {
      padding: 5px 0;
    }
  }
  }
`;

export default function CommandesListeST({className}) {
  const [dataOperations, setDataOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openedItems, setOpenedItems] = useState({});
  const user = useSelector(state => state?.user?.user?.Contact);
  const toggleOpen = (id) => {
    setOpenedItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/membreoperationliste`,{Nature : "C" })
    .then((response) => {
      console.log("response", response);
      setDataOperations(response?.data?.Commandes);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);
  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

    return (
        <Wrap className={className}>
        {isLoading && <SpinnerInlineST/>}
        {dataOperations ? <>
            <h3 className="center homemadeTitle initial orange">Mes commandes</h3>
            
            <List style={{width:"100%"}}
                itemLayout="vertical"
                size="large"
                pagination={{
                    position: 'bottom',
                    align: 'start',
                    total: dataOperations?.length || 0,
                    showTotal: (total, range) => `Commande ${range[0]}-${range[1]} sur ${total} au total`, // Function to display the range of items and total
                    defaultPageSize: 5, // Number of items per page by default
                    defaultCurrent: 1, // Initial page
                    pageSizeOptions: ['10', '20', '30'], // Options for changing the page size
                    // onChange: (page, pageSize) => {
                    //   // Function to handle page change
                    // },
                  }}
                  dataSource={dataOperations && dataOperations}
                  renderItem={(item) => {
                    console.log("Rendering item:", item); 
                    return (
                        <ListItemStyled>
                            <div>
                              <p>Commande n° <b className="orange">{item?.NumDoc}</b></p>
                              <p>Date de la commande: <span className="orange">{formatDate(item?.Date)}</span></p>
                              <p>Montant total: <span className="orange">{item?.MontantTTC}€</span></p>
                            <div>
                              <BoutonST background={"#f05b32"} className="medium" 
                              lib={openedItems[item.ID] ? "Moins de détails" : "Plus de détails"} 
                              onClick={() => toggleOpen(item.ID)}
                              />
                            {openedItems[item.ID] && <StyledFade className="flex mt20">
                              <ListItemStyled>
                                   <table className="greenTable">
                                   <thead>
                                     <tr>
                                       <th className="greenTeble">Article</th>
                                       <th>Prix</th>
                                     </tr>
                                   </thead>
                                   <tbody>
                                     {item?.Lignes?.map((ligne, index) => (
                                       <tr key={index}>
                                         <td>{ReactHtmlParser(ligne?.Lib)}</td>
                                         <td>{ligne?.MontantTTC}€</td>
                                       </tr>
                                     ))}
                                   </tbody>
                                 </table>
                              </ListItemStyled>
                              </StyledFade>}
                              </div>
                            </div>
                          
                        </ListItemStyled>
                    );
                  }}
                />
                </> : <h3 className="center homemadeTitle initial orange">Vous n'avez pas de commandes</h3>}
        </Wrap>
  );
}
