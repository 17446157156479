import React, { useState, useEffect, useRef } from "react";
import {useLocation} from "@reach/router";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { AiOutlineEnvironment,AiOutlineUser,AiOutlineShoppingCart,AiOutlineMenu,AiOutlineHeart,AiOutlineSearch,AiOutlineClose } from "react-icons/ai";
import { FaChevronRight,FaChevronDown,FaCheck } from "react-icons/fa6";
import { Layout, Menu } from "antd";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { WrapperForm } from "../DesignSystem/SelfTissus/Containers";
import Logo from "../../assets/images/Logo.svg";
import breakpoints from "../../constants/breakpoints";
import FormSearchST from "../FormsST/FormSearchSelf";
import DrawerElems from "./DrawerElemMobST";
import picDefault from "../../assets/images/photo2.png";
import { Logged, Number, Ville } from "../DesignSystem/Commons/Elements";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Icons = styled.div`
display: flex;
display: flex;
align-items: flex-end;
gap: 10px;

`
const Inside = styled.div`
display: flex;
gap: 10px;
flex-wrap: nowrap;
@media only screen and (max-width: 1390px) {
  justify-content: center;
}
`

const Wrap = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin-left: 30px;
align-items: center;
@media only screen and (max-width: 1275px) {
  margin-left: 5px;
}
@media only screen and (max-width: 1590px) {
  /* margin-left: 0; */
  flex-direction: column-reverse;

}
`;


const StyledNavLink = styled(NavLink)`
position: relative;
white-space: nowrap;
cursor: pointer;
z-index: 1;
color: ${(props) => props.theme.grey03ST};
text-decoration: none;
display: inline-block;
text-transform: uppercase;
font-size: 14px;
&>svg {
  width: 11px;
  height: 11px;
  margin-left: 5px;
}
&.noSubMenu {
  &>svg {
    display: none;
  }
}
&.all {
  color: ${(props) => props.theme.redST};
  text-transform: initial;
  padding: 20px 0;
  font-weight: 500;
}
&.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  &>svg {
    width: 30px;
    height: 30px;
  }
}
&.selected {
  color: ${(props) => props.theme.greenST};
  font-weight: 700;
}
&.menu2 {
  font-weight: 500;
  text-transform: initial;
}
  &:hover {
    color: ${(props) => props.theme.redST};
  }
  &.active {
    color: ${(props) => props.theme.redST} !important;
  }
`;

const StyledDiv = styled(Navbar)`
  background: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  
  & > div {
    padding: 15px 100px!important;
    @media only screen and (max-width: 630px) {
      padding: 0 1.6rem !important;
    }
    @media only screen and (max-width: 470px) {
    justify-content: center !important;
    }
  }

  @media only screen and (max-width: 992px) {
    &.fixed-nav {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: center;
  }
  justify-content: center;

`;

const StyledDivLienWrapper = styled(Nav)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const MenuNiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
  &.img {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  `;

const StyledDivLien = styled(NavItem)`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: absolute;
  top: 116px;
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  &.white {
    background: #fff;
    border-top: 2px solid ${(props) => props.theme.grey01ST};
  }
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
  align-items: baseline;
 
 
  /* @media only screen and (max-width: 1543px) {
    top: 172px;
  } */
  @media (max-width: ${breakpoints.xl}) {
    position: relative;
    offset-position: 0px -50px;
    top: 0;
    flex-direction: column;
    gap: 20px;
    display: contents;
  }
`;


const StyledNavBarBrandLG = styled(NavbarBrand)`
  margin: 0;
  display: none;
  border-bottom: none !important;
  @media (min-width: ${breakpoints.xl}) {
    display: block;
    padding: 10px 0;
  }
`;

const StyledNavBarBrandXS = styled(NavbarBrand)`
  border-bottom: none !important;
  @media (min-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const StyledImg = styled.img`
`;

const StyledNav = styled(Nav)`
  box-sizing: border-box;
  display: flex;
  padding: 0px 40px;
  //gap: 50px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;
const StyledNavbarToggler = styled(NavbarToggler)`
  border: none;
  display: none;
`;

const StyledNavbarTogglerResp = styled(StyledNavbarToggler)`
  position: static;
  top: 175px;
  right: 10px;
  z-index: 999;
  gap: 20px;
  display: flex;
  align-items: center;
  &>svg {
    width: 25px;
    height: 25px;
    &:hover {
      cursor: pointer;
      fill: ${(props) => props.theme.redST};
    }
  }
  @media screen and (min-width: 1200px) {
    position: static;
    bottom: auto;
    right: auto;
  } 
  @media screen and (max-width: 870px) {
    top: 200px;
  }
  @media screen and (max-width: 768px) {
    top: 250px;
    &.hiddenBandeau {
    top: 90px;
    }
  }
  @media screen and (max-width: 610px) {
    top: 270px;
  } 
  @media screen and (max-width: 460px) {
    top: 300px;
  }
  @media screen and (max-width: 410px) {
    top: 320px;
    &.hiddenBandeau {
    top: 110px;
    }
  }
`;




const StyledDiv_2 = styled(NavLink)`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
width: 100%;
padding: 15px 20px;
cursor: pointer;
gap: 20px;
align-items: center;
justify-content: space-between;
cursor: pointer;
text-transform: initial;
font-size: 16px;
&> svg {
  width: 13px;
  height: 13px;
}
  &.active {
    background-color: ${(props) => props.theme.rose01ST};
    color: ${(props) => props.theme.grey03ST} !important;
  }
  &:hover {
    color: ${(props) => props.theme.grey03ST} !important;
  }
  &.img {
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 10px;
    color: ${(props) => props.theme.grey03ST};
  }

&.noSubMenu, &.img.subMenu {
  &>svg {
    display: none;
  }
}
  
`;

const ImgStyled = styled.img`
max-width: 250px;
max-height: 250px;
/* width: 250px;
height: 250px; */
`

const StyledDiv_4 = styled(NavItem)`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
width: 100%;
padding: 15px 20px;
cursor: pointer;
  &.img {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: ${(props) => props.theme.grey03ST};
  }
`;

const WrapListes = styled.div`
width: 80%;
background: #fff;
overflow: auto;
height: 75vh;
&.img {
  display: none;
}
&.img.imgSubMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;  
}
`
const SousMenuListe = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
align-content: center;
width: 100%;
margin: auto;
gap: 20px;
/* padding: 30px 0; */
background-color: #fff;
flex-wrap: wrap;
&.img.imgSubMenu {
  justify-content: center;
  margin: 0;
}
`

const ListeTitle = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
font-size: 15px;
margin: 0 50px;
text-align: left;
color: ${(props) => props.theme.grey03ST};

&.noSub{
  /* margin: 0 10px; */
  width: 20%;
}
`
const Title = styled(NavLink)`
font-weight: 800;
margin-bottom: 10px;
border-bottom: 1px solid ${(props) => props.theme.grey05ST};
cursor: pointer;
&.noSub {
  margin-bottom: 0;
}
`
const SousMenuListe2 = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
`

const LinkNiv4 = styled(NavLink)`
padding: 0 25px 10px 0;
font-size: 13px;
cursor: pointer;
`

const User=styled.div`
position: relative;
  &>svg {
  width: 25px;
  height: 25px;
  fill: ${(props) => props.theme.grey04ST};
    &:hover {
      cursor: pointer;
      fill: ${(props) => props.theme.redST};
    }
  }

`

export function NavBarSelf({ classNameB, dataMenuHaut }) {
  const basketItems = useSelector(state => state?.basket?.totalItems ? state?.basket?.totalItems : 0);
  const favItems = useSelector(state => state?.favoris?.totalItems ? state?.favoris?.totalItems : 0);
  const mag = useSelector(state => state?.magasin?.magasin);
  const navbarRef = useRef(null);
  const navbarRef2 = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemSous, setActiveItemSous] = useState(null);
  const [activeItemSous2, setActiveItemSous2] = useState(null);
  const [activeItemSous3, setActiveItemSous3] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState({});
  // const [isShown, setIsShown] = useState(false);
  const [isShownSous, setIsShownSous] = useState(false);
  const [dropdownOpenSous, setDropdownOpenSous] = useState({});
  const [dropdownOpenSous2, setDropdownOpenSous2] = useState({});
  const [navTogglerResp, setNavTogglerResp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleElem, setVisibleElem] = useState(false);
  const [showFormSearch, setShowFormSearch] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [elem, setElem] = useState("");
  const [activeItems, setActiveItems] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  let nomMag = mag ? mag.Nom : "WEB";
  const user = useSelector(state => state.user?.user?.Contact);
  // console.log("user", user);
  //  useEffect(() => {
  //   axios.get(`/menuhaut?{"CleCtrl":"TaTaYoYo","act":"MenuHaut"}`).then(res => {
   
  //     setDataMenuHaut(res.data?.MenuHaut ? res.data.MenuHaut : []);
  //   }
  //   ).catch(err => {
  //     console.log(err);
  //   }
  //   );
  // }
  // ,[]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const showDrawerMenu = () => {
    setVisibleMenu(true);
  };

  const onCloseMenu = () => {
    setVisibleMenu(false);
    setOpenKeys([]);
  };
  const showDrawerElem = () => {
    setVisibleElem(true);
  };

  const onCloseElem = () => {
    setVisibleElem(false);
  };
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
        setDropdownOpen({});
        setDropdownOpenSous({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleIsOpen = (id) => {
    setActiveItem(id);
    setActiveItems(prevState => ({ ...prevState, level1: id }));
    setDropdownOpen((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      newState[id] = !prevState[id];
      return newState;
    });

    // Set all dropdownOpenSous to false
    setDropdownOpenSous((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      return newState;
    });
  };

  const handleIsOpenSous = (id) => {
     console.log("id", id);
    setActiveItemSous(id);
    setActiveItems(prevState => ({ ...prevState, level2: id }));
    setDropdownOpenSous((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      newState[id] = !prevState[id];
      return newState;
    });
  };  
  
  const handleIsOpenSous2 = (id) => {
    setActiveItems(prevState => ({ ...prevState, level3: id }));
    setActiveItemSous2(id);
    setDropdownOpenSous2((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      newState[id] = !prevState[id];
      return newState;
    });
    
  };
  const handleIsOpenSous3 = (id) => {
    setActiveItems(prevState => ({ ...prevState, level4: id }));
    setActiveItemSous3(id);
    
  };

  const handleLogin = (isSuccessful) => {
    console.log("isSuccessful", isSuccessful);
    setIsLoggedIn(isSuccessful);
  };



  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    const isNiv1 = latestOpenKey && latestOpenKey.startsWith('sub');
    setOpenKeys(isNiv1 ? [latestOpenKey] : keys);
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
      setNavTogglerResp(true);
      } else {
        setNavTogglerResp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef2.current && !navbarRef2.current.contains(event.target)) {
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeNav = () => {
    setIsOpen(false);
    setDropdownOpen({});
    setDropdownOpenSous({});
  };



 
  const TogglerResp = ({ onClick, className, children }) => (
    <StyledNavbarTogglerResp tag={"div"}  className={classNameB}>
      {children}
    </StyledNavbarTogglerResp>
  );
  return (
    <div ref={navbarRef} id="menu">
{/* <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} defaultOpenKeys={['Projets']} /> */}
      <WrapperForm className={showFormSearch ? "show" : ""}>
        <FormSearchST />
        <AiOutlineClose onClick={() => setShowFormSearch(false)}/>
      </WrapperForm>
      <StyledDiv expand="xl" container="fluid" className={`g-0 ${isScrolled ? 'fixed-nav' : ''}`} onMouseLeave={() => {
        closeNav();
      }}>
        <StyledNavBarBrandXS href="/">
          <StyledImg src={Logo} alt="logo Self Tissus"/>
        </StyledNavBarBrandXS>
        <TogglerResp>
          <AiOutlineSearch onClick={() => { setShowFormSearch(true); }} />
          <User><AiOutlineEnvironment onClick={() => { showDrawerElem(); setElem("geo"); }} /><Ville className="mob">{nomMag}</Ville></User>
          <User><AiOutlineUser onClick={() => { showDrawerElem(); setElem("compte"); }} /> {user && <Logged className="mob"><FaCheck/></Logged>}</User>
          <User><AiOutlineShoppingCart onClick={() => { showDrawerElem(); setElem("panier"); }} /><Number className="mob"><span>{basketItems}</span></Number></User>
          <User><AiOutlineHeart onClick={() => { showDrawerElem(); setElem("favoris"); }} /><Number className="mob"><span>{favItems}</span></Number></User>
          <AiOutlineMenu onClick={() => { showDrawerElem(); setElem("menu"); }}/>
        </TogglerResp>
        <Collapse isOpen={isOpen} navbar>
          
          <StyledNav className="me-auto" navbar>
          <StyledNavBarBrandLG href="/" >
            <StyledImg src={Logo} />
          </StyledNavBarBrandLG>
          <Wrap>
            <Inside>
            {dataMenuHaut &&
              dataMenuHaut
                .sort((a, b) => (a.Tri < b.Tri ? -1 : 1))
                .map(
                  (menu, i) => (
                    (
                      <React.Fragment key={i}>
                        <StyledDivLienWrapper key={menu?.ID+"_1"}>
                          <StyledNavLink
                            key={menu?.ID+"_2"}
                            href={menu?.slug}
                            // isShown={dropdownOpen[menu.ID] === true}
                            className={`niv1 ${menu.ID === activeItem ? 'active' : ''} ${menu?.SousMenu && menu?.SousMenu?.length > 0 ? '' : 'noSubMenu'} ${menu?.slug.replace(/\/$/, '') === window.location.pathname.replace(/\/$/, '') ? 'selected' : ''}`}                          
                            // onClick={() => {
                            //   handleIsOpen(menu.ID);
                            //   setActiveItem(menu.ID);
                            // }}
                            onMouseEnter={() => {
                              handleIsOpen(menu?.ID);
                              setActiveItem(menu?.ID);
                            }}
                            onMouseLeave={() => {
                              setActiveItem(null);
                            }}
                            // isActive={activeItem === menu.ID}
                            // nav
                            style={{color: menu?.TitreCouleur}}
                          >
                            {menu?.lib}<FaChevronDown/>
                            
                          </StyledNavLink>
                          
                        </StyledDivLienWrapper>
                            {dropdownOpen[menu?.ID] && (
                              <StyledDivLien show={dropdownOpen[menu?.ID] === true ? "true" : "false"} key={menu?.ID+"_3"}>
                                <MenuNiv2 className={menu?.SousMenu?.some(sous => sous?.image) ? 'img' : ''}>
                                {menu?.SousMenu &&
                                  menu?.SousMenu.map((sous, i) => (
                                    <StyledDiv_2 key={sous?.ID} className={`${menu?.SousMenu?.some(sous => sous?.image) ? 'img' : ''} ${sous.ID === activeItemSous ? 'active' : ''} ${sous?.SousMenu && sous?.SousMenu?.length>0 ? "subMenu" : "noSubMenu"}`}
                                    href={sous?.slug}
                                    onMouseEnter={() => {
                                      if (sous?.SousMenu && sous?.SousMenu?.length > 0) {
                                        handleIsOpenSous(sous?.ID);
                                      }
                                      setActiveItem(menu?.ID);
                                    }}
                                    
                                    >
                                      {
                                        sous?.image ?  (<ImgStyled src={sous?.image?.url}  onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = picDefault;
                                          }} loading="lazy"/>) : null
                                      }
                                    {sous?.lib}<FaChevronRight/></StyledDiv_2>
                                  ))}
                                  </MenuNiv2>
                                  {dropdownOpen[menu?.ID] && menu?.SousMenu &&
                                    menu?.SousMenu.map((niv2, i) => (
                                      dropdownOpenSous[niv2?.ID] && (
                                        <WrapListes key={niv2?.ID} className={`${menu?.SousMenu?.some(niv2 => niv2.image) ? 'img' : ''} ${niv2?.SousMenu && niv2?.SousMenu?.length > 0 ? 'imgSubMenu' : ''}`}>
                                        <SousMenuListe show={dropdownOpen[niv2?.ID] === true ? "true" : "false"} className={`${menu?.SousMenu?.some(niv2 => niv2?.image) ? 'img' : ''} ${niv2?.SousMenu && niv2?.SousMenu?.length > 0 ? 'imgSubMenu' : 'noSub'}`}>
                                          {niv2?.SousMenu &&
                                            niv2?.SousMenu.map((niv3) => (
                                              <ListeTitle key={niv3?.ID} className={`${niv2?.SousMenu && niv3?.SousMenu?.length > 0 ? 'subMenu' : 'noSub'}`}>
                                                <Title className={`${niv2?.SousMenu && niv3?.SousMenu?.length > 0 ? 'subMenu' : 'noSub'}`}
                                                onMouseEnter={() => {
                                                  setActiveItemSous2(niv3?.ID);
                                                }}
                                                href={niv3?.slug}
                                                >{niv3?.lib}</Title>
                                                <SousMenuListe2 >
                                                {niv3?.SousMenu &&
                                                  niv3?.SousMenu.map((niv4) => (
                                                      <LinkNiv4 key={niv4?.ID}
                                                      href={niv4?.slug}
                                                      onMouseEnter={() => {
                                                        handleIsOpenSous3(niv4?.ID);
                                                        setActiveItemSous3(niv4?.ID);
                                                      }}
                                                      >{niv4?.lib}</LinkNiv4>
                                                  ))
                                                }
                                                 </SousMenuListe2>
                                              </ListeTitle>
                                            ))
                                          }
                                        </SousMenuListe>
                                        </WrapListes>
                                      )
                                    ))
                                  }
                              </StyledDivLien>
                            )}

                            
                          </React.Fragment>
                    )
                  )
                )}
                 
                </Inside>
                <Icons>
                  <StyledNavLink
                  className="icons"
                    key="1"
                    // nav
                    tag={"div"}
                    onClick={() => { setShowFormSearch(true);}}
                  >
                    <AiOutlineSearch/>
                    
                  </StyledNavLink>
                  <StyledNavLink
                  className="icons"
                    key="2"
                    // nav
                    href="/localisation"
                  >
                    <AiOutlineEnvironment/>
                    <Ville>{nomMag}</Ville>
                  </StyledNavLink>
                  <StyledNavLink
                  className="icons"
                    key="3"
                    // nav
                    href="/membre"
                  >
                    <AiOutlineUser/>
                    {user && <Logged><FaCheck/></Logged>}
                  </StyledNavLink>
                  <StyledNavLink
                  className="icons"
                    key="4"
                    // nav
                    href="/panier"
                  >
                    <AiOutlineShoppingCart/>
                    <Number><span>{basketItems}</span></Number>
                  </StyledNavLink>
                  <StyledNavLink
                    key="5"
                    className="icons"
                    href="/favoris"
                  >
                    <AiOutlineHeart />
                    <Number><span>{favItems}</span></Number>
                  </StyledNavLink>
                  </Icons>
          </Wrap>
          </StyledNav>
          
        </Collapse>
        <>
          {/* <DrawerMenu data={data} visible={visibleMenu} onClose={onCloseMenu} /> */}
          <DrawerElems visible={visibleElem} onClose={onCloseElem} elem={elem} data={dataMenuHaut}/>
        </>
      </StyledDiv>
    </div>
  );
}
export default NavBarSelf;
