import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { Drawer,Layout, Space } from "antd";
import { 
  TabContent, TabPane, 
  Row, Col , Nav, NavItem, NavLink,
} from 'reactstrap'; 
import img from "../../assets/images/img1.jpg";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { AiOutlineClose,AiFillStar,AiFillGift,AiOutlineShoppingCart } from "react-icons/ai";
import { BiSolidOffer } from "react-icons/bi";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
const { Sider } = Layout;

const Welcome = styled.div`
position: relative;
color: #fff;
text-shadow: 2px 2px 4px #000000;
font-size: 35px;
font-family: "Assistant", sans-serif;
font-weight: 500;
text-align: center; 
`

const Plus = styled.div`
position: relative;
bottom: -35px;
/* transform: translateX(-50%);
top: 160px;
left: 50%; */
z-index: 1;
width: 100%;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
background-color: ${props => props.theme.grey08ST};
border-radius: 10px;
padding: 10px;
color: ${props => props.theme.blackST};
text-shadow:none;
font-size: 35px;
font-family: "Assistant", sans-serif;
font-weight: 500;
text-align: center;
box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
overflow: hidden;
&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 80%;
  height: 190px;
  background-color: ${props => props.theme.grey01ST};
  right: -40%;
  top: 20px;
  z-index: -1;
  opacity: 0.5;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 82%;
  height: 145px;
  background-color: ${props => props.theme.greenST};
  left: -20%;
  bottom: -14px;
  -webkit-clip-path: ellipse(100% 15% at -15% 100%);
  -webkit-clip-path: ellipse(93% 63% at -42% 97%);
  clip-path: ellipse(93% 63% at -30% 69%);
  z-index: -1;
  opacity: 0.5;
}
`

const StyledDrawer = styled(Drawer)`

&>.ant-drawer-header {
  background-image: url(${img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 25px;
}
  & > .ant-drawer-body {
    padding: 10px 0 !important;
  } 
  &>.ant-drawer-footer {
    background-color: #12121208;
    border: none;
  }
  & >.ant-drawer-header > .ant-drawer-extra {
    width: 100%;
  }
  & >.ant-drawer-header > .ant-drawer-header-title {
    display: none;
  }
  & > .ant-drawer-body > aside {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    & > div > ul > li > div > i {
    }
  }
  
`;

const SpaceStyled=styled(Space)`
width: 100%;
display: flex;
flex-direction: column;
&>.ant-space-item {
  width: 100%;
  &> div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &> svg {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    color: ${props => props.theme.redST};
  }
}
}
`

const Txt = styled.div`
font-size: 18px;
font-family: "Assistant", sans-serif;
font-weight: 500;
text-align: left;
position: relative;
cursor: pointer;
&:hover {
  color: ${props => props.theme.redST};
}
&>svg {
  margin-right: 10px;
  font-size: 25px;
}

&>span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.grey03ST};
}

&.small {
  font-size: 16px;
  padding: 5px;
}
`;

const TabsWrap = styled.div`
margin-top: 25px;
&>span.center {
  display: block;
  font-size: 20px;
  font-family: "Assistant", sans-serif;
  text-align: center;
  color: ${props => props.theme.grey03ST};
  margin-bottom: 10px;

}
`;

const NavLinkStyled = styled(NavLink)`
font-size: 20px;
border: none !important;
color: ${props => props.theme.grey03ST} !important;
background-color: transparent !important;
&.active {
color: ${props => props.theme.redST} !important;
border-bottom: 2px solid ${props => props.theme.redST} !important;
}
&:hover>div {
text-decoration: none;
color: ${props => props.theme.redST};
}
`;

const NavItemStyled = styled(NavItem)`
@media only screen and (max-width: 768px) {
  width: 100%;
}

`;


const content1tab = (
  <div>
    <Txt className="small"><AiOutlineShoppingCart/>Vos achats récompensés
    <span>Gagnez 1 points pour chaque 1 € dépensé</span>
    </Txt>
    <Txt className="small"><BiSolidOffer/>Liker notre page Facebook
    <span>Gagnez 10 points</span>
    </Txt>
    <Txt className="small"><BiSolidOffer/>Nous suivre sur Instagram<span>Gagnez 10 points</span></Txt>
    <Txt className="small"><BiSolidOffer/>Ecrire un avis<span>Gagnez 50 points pour chaque avis</span></Txt>
  </div>
);

const content2tab = (
  <div>
    <Txt className="small"><BiSolidOffer/>New Reward Program</Txt>
  </div>
);
const tabData = [
  { label: <Txt><AiFillStar/>Comment gagner points</Txt>, content: content1tab, disabled: false },
  { label: <Txt><AiFillGift/>Comment utiliser points</Txt>, content: content2tab, disabled: false },
];


export default function DrawerInfoST({ data,visible,onClose }) {
  const [activeTab, setActiveTab] = useState(0);
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
return (
      <>
     
        <StyledDrawer
        placement="right"
        closable={true}
        onClose={onClose}
        open={visible}
        extra={
          <>
          <SpaceStyled>
            <AiOutlineClose onClick={onClose}/>
            <Welcome>
                Bienvenue dans notre magasin
            </Welcome>
          </SpaceStyled>
           <Plus>
           <h4 style={{margin:"0.4rem auto"}}>Adhérez et gagnez des récompenses</h4>
           <p>Obtenez des récompenses pour être nos clients</p>
           <BoutonNoirLnkST lib="Rejoindre le programme" href="/communaute/votre-fidelite-recompensee/"/>
           <p>Déjà membre? <LienNoirST lib="S'identifier" href="/membre"/></p>
         </Plus>
         </>
        }
        >
        <Sider width={450}>
            <TabsWrap>
            <span className="center">POINTS</span>
            <Nav tabs style={{justifyContent:"center",marginBottom:"15px"}}>
              {tabData.map((tab, index) => (
                <NavItemStyled className={index} key={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </Nav>
            <TabContent activeTab={activeTab} style={{padding:'0 20px'}}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
            </TabsWrap>
        </Sider>

</StyledDrawer>
</>
    );
  }