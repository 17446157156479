import React from "react";
import styled, { keyframes } from "styled-components";
import ArticlePhotoST from "./ArticlePhoto";
import ArticleDescST from "./ArticleDesc";

const radioWave = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  90% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
const beatingHeart = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1.1);
  }
`;

const Prod = styled.div`
position: relative;
display: flex;
align-items: flex-start;
/* overflow-y: auto;
overflow-x: hidden;
height: 100vh; */
&>.image-gallery{
    width: 98%;
    margin-top: 0;
    @media screen and (max-width: 786px) {
        width: 100%;
    }
}

@media screen and (max-width: 786px) {
    flex-direction: column;
    align-items: center;
}
`;
export default function ArticleST({data}) {
  return (
  
   <div>
    <Prod>
        <ArticlePhotoST className="img" data={data?.Produit?.Articles} dataProd={data?.Article}/>
        <ArticleDescST className="desc" data={data?.Article}/>
    </Prod>
   </div>
    
  );
}

