import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { Section, WrapperProds } from "../DesignSystem/SelfTissus/ContainersST";
import { InputStyled, FormStyled } from "../DesignSystem/SelfTissus/Forms";
import { Card, Badge } from 'antd';
import picDefault from "../../assets/images/photo.jpg";
import ReactHtmlParser from 'react-html-parser';
import { FaCheck,FaRegWindowClose,FaCaretUp,FaCaretDown  } from "react-icons/fa";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const SiderStyled = styled(Sider)`
min-width: 50% !important;
width: 70% !important;
background-color: #ffffff;
border-radius: 5px;
display: none;
@media screen and (max-width: 768px) {
  display: block;
}
`; 

const CardStyled = styled(Card)`
width: 400px;
min-height: 400px;
@media screen and (max-width: 992px) {
  width: 300px;
}
@media screen and (max-width: 768px) {
  width: 100%;
  margin: 0 auto;
  max-height: unset;
  min-height: unset;
}
&>h2 {
  font-size: 17px !important;
  margin: 0;
}
`;

const Prods = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 50px;
width: 70%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`;

const FilterProds = styled.div`
width: 20%;
@media screen and (max-width: 768px) {
  display: none;
}
`;
const FilterProds2 = styled.div`
display: flex;
align-items: center;
width: 100%;
gap: 50px;
justify-content: flex-end;
@media screen and (max-width: 768px) {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
`;

const Tri = styled.p`
font-size: 20px;
color: ${props => props.theme.grey09ST};
border-bottom: 1px solid ${props => props.theme.grey09ST};
`;

const Link = styled.button`
text-align: left;
text-decoration: none;
border: none;
background-color: transparent;
position: relative;
cursor: pointer;
color: ${props => props.theme.grey06ST};
&.selected {
  color: ${props => props.theme.redST};
  font-weight: 600;
}
&.sub {
  font-size: 14px;
  color: ${props => props.theme.grey02ST};
}
&:hover {
  color: ${props => props.theme.redST};
  text-decoration: none;
  background-color: transparent;
}
&.actif {
  font-weight: 600;
}
`;

const Result = styled.p`
font-family: "Assistant", sans-serif !important;
border-color: ${props => props.theme.grey02ST};
text-transform: uppercase;
`

const Bouton = styled.button`
display: none;
align-items: center;
justify-content: center;
text-decoration: none;
text-align: center;
text-transform: uppercase;
border: none;
background-color: ${props => props.theme.orangeST};
padding: 18px;
position: relative;
cursor: pointer;
color: #fff;
font-weight: 600;
font-size: 15px;
transition: all .2s ease-out;
&>svg {
  font-size: 25px;
  margin-right: 7px;
}
&:hover {
    opacity: 0.8;
}
@media screen and (max-width: 768px) {
  display: flex;
  width: 100%;
}
`;

export default function ArticlesListeST({data, cats}) {

const [isLoading, setIsLoading] = useState(true); 
const [filteredData, setFilteredData] = useState([]);
const [dataProduitsNbr, setDataProduitsNbr] = useState(data?.length || 0);
const [filterOption, setFilterOption] = useState('');
const [idCat, setIdCat] = useState('');
const [showFiltersMob, setShowFiltersMob] = useState(false);
console.log("showFiltersMob", showFiltersMob);
const handleFilterChange = (mode,option) => {
  console.log("mode", mode);
  console.log("option", option);
  if (mode === 'tri') {
    if (typeof option === 'string') {
      setFilterOption(option);
    } else {
      setFilterOption(option.target.value);
    }
  } else if (mode === 'filter') {
    setIdCat(option);
  }
};

const toggleSider = () => {
  setShowFiltersMob(prevState => !prevState);
};

useEffect(() => {
  let filteredData = [...data];
  switch (filterOption) {
    case 'PrixC':
      filteredData?.sort((a, b) => a?.Prix - b?.Prix);
      break;
    case 'PrixD':
      filteredData?.sort((a, b) => b?.Prix - a?.Prix);
      break;
    case 'a':
      filteredData?.sort((a, b) => {
        if (!a?.libArticle) return 1;
        if (!b?.libArticle) return -1;
        return a.libArticle.localeCompare(b.libArticle);
      });
      break;
    case 'z':
      filteredData?.sort((a, b) => {
        if (!a?.libArticle) return 1;
        if (!b?.libArticle) return -1;
        return b.libArticle.localeCompare(a.libArticle);
      });
      break;
    default:
      break;
  }
  if (idCat) {
    console.log("idCat", idCat);
    filteredData = filteredData.filter(item => {
      return item.CategorieWeb && item.CategorieWeb.some(catId => catId === idCat);
    });
    console.log("filteredData after filter", filteredData);
  }
  setFilteredData(filteredData);
  setDataProduitsNbr(filteredData.length);
}, [data, idCat, filterOption]);

const getBadgeProps = (item) => {
  if (item?.ArticleSemaine) {
      return { text: "Article de la semaine", color: "#79b69f" };
  } else if (item?.Abajourable) {
      return { text: "Abajourable", color: "#da46da" };
  } else if (item?.Nouveaute) {
      return { text: "Nouveauté", color: "#07af5b" };
  } else if (item?.PromoMois) {
      return { text: "Promo du mois", color: "#5089d7" };
  } else if (item?.PromoWeb) {
      return { text: "Promo Web", color: "#f05b32" }; 
  }
  return { text: "", color: "" };
};
  return (
    <>
   
      <Section>
         <WrapperProds>
          <FilterProds>
          <ul className="none">
            <Tri>Trier par</Tri>
            <li><Link onClick={() => handleFilterChange("tri",'PrixC')}>Prix croissant</Link></li>
            <li><Link onClick={() => handleFilterChange("tri",'PrixD')}>Prix décroissant</Link></li>
            <li><Link onClick={() => handleFilterChange("tri",'a')}>De A à Z</Link></li>
            <li><Link onClick={() => handleFilterChange("tri",'z')}>Z à A</Link></li>
          </ul>
          {cats && 
          <ul className="none">
            <Tri>Filtrer par</Tri>
            {
              cats.map((cat, i) => (
                <li key={i}><Link className={idCat === cat?.ID ? 'selected' : ''} onClick={() => handleFilterChange("filter",cat?.ID)}>{cat?.lib}</Link>
                {cat.SousCategorieWeb?.map((subCat, j) => (
                  <li key={`${i}-${j}`} style={{ paddingLeft: '20px' }}>
                    <Link
                      className={["sub",idCat === subCat?.ID ? 'selected' : '']}
                      onClick={() => handleFilterChange("filter", subCat?.ID)}
                    >
                      {subCat?.lib}
                    </Link>
                  </li>
                ))}
                </li>
                
              ))
            }
            {idCat && (<BoutonST className={"small"} lib={"Effacer les filtres"} background={props => props.theme.orangeST} onClick={() => handleFilterChange("filter",'')} />)}
          </ul>}
          </FilterProds>
          <Prods>
            <FilterProds2>
            <FormStyled>
              <InputStyled
                  className="mb-3 min"
                  type="select"
                  style={{padding:"0.5rem 1.05rem",width:"auto"}}
                  onChange={(event) => handleFilterChange('tri', event.target.value)}
                >
                  <option value="">Trier par</option>
                  <option value="PrixC">Prix croissant</option>
                  <option value="PrixD">Prix décroissant</option>
                  <option value="a">De A à Z</option>
                  <option value="z">Z à A</option>
                </InputStyled>
              </FormStyled>
              <Result>{dataProduitsNbr} produits</Result>
              <Bouton onClick={toggleSider} className={showFiltersMob ? 'on' : 'off'}>
              {showFiltersMob ? <FaCaretUp className="minus" /> : <FaCaretDown className="plus" />}
                Filtres</Bouton>
            </FilterProds2>
            {showFiltersMob &&
              <SiderStyled width={"100%"}>
              {cats && 
                    <ul className="none">
                      <Tri>Filtrer par</Tri>
                      {
                        cats.map((cat, i) => (
                          <li key={i}><Link className={idCat === cat?.ID ? 'selected' : ''} onClick={() => handleFilterChange("filter",cat?.ID)}>{cat?.lib}</Link>
                          {cat.SousCategorieWeb?.map((subCat, j) => (
                            <li key={`${i}-${j}`} style={{ paddingLeft: '20px' }}>
                              <Link
                                className={["sub",idCat === subCat?.ID ? 'selected' : '']}
                                onClick={() => handleFilterChange("filter", subCat?.ID)}
                              >
                                {subCat?.lib}
                              </Link>
                            </li>
                          ))}
                          </li>
                          
                        ))
                      }
                      {idCat && (<BoutonST className={"small"} lib={"Effacer les filtres"} background={props => props.theme.orangeST} onClick={() => handleFilterChange("filter",'')} />)}
                    </ul>}
                </SiderStyled>
              }
              {filteredData?.length === 0 && <p>Aucun produit trouvé</p>}
          {
            data && filteredData.map((item, i) => {
              const { text, color } = getBadgeProps(item);
              return (
                <Badge.Ribbon style={{display:(text !== "") ? "block" : "none"}}
                text={text} color={color} placement={"start"} key={i}
                >
                <CardStyled
                  onClick={() => window.location.href = item?.slug || '#'}
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={
                    <img
                      alt="photo de l'article"
                      src={item?.Articles?.[0]?.Photos?.[0]?.image?.url || picDefault}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = picDefault;
                      }}
                      loading="lazy"
                    />
                  }
                >
                  <h2 className="grey center fs23">{ReactHtmlParser(item?.libArticle)}</h2>
                  <p className="center uppercase fw600"><span className="gras ">Prix PUTTC : </span><span className="center uppercase orange fw600">{item?.Prix+" €"}</span></p>
                </CardStyled>
                </Badge.Ribbon>
              );
            })
          }
          </Prods>  
        </WrapperProds>
    
      </Section>
      </>
  );
}