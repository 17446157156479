import React, {useState, useEffect} from "react";
import axios from "axios";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { Card } from 'antd';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import { FaTrashAlt,FaPen } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import FormRealisationST from "../FormsST/FormRealisation";
import {WrapFormPop} from "../DesignSystem/PopUpST";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import picDefault from "../../assets/images/photo.jpg";

const { Meta } = Card;

const Wrap = styled.div`
position: relative;
display: flex; 
flex-wrap: wrap;
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
`;

const Cards = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
align-items: flex-start;
width: 100%;
gap: 30px;
`;

const Prods = styled.div`
margin-top: 30px;
display: flex;
flex-wrap: wrap;
width: 100%;
gap: 10px;
`;

const Titre = styled.span`
font-size: 18px;
color: ${(props) => props.theme.greenST};
font-family: "Assistant", sans-serif;
`;

const LinkProd = styled.a`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 10px;
&:hover {
  color: ${(props) => props.theme.redST};
}

`;

const Date = styled.span`
position: absolute;
right: 25px;
color: ${(props) => props.theme.greenST};
font-family: "Assistant", sans-serif;
`;

const ImageProd = styled.img`
width: 50px;
height: 50px;
`;

const CardStyled = styled(Card)`
width: 400px;
border-radius: 8px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
font-family: "Assistant", sans-serif;
.ant-card-cover {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  width: 100% !important;
}
`;

const MetaStyled = styled(Meta)`
&>div>.ant-card-meta-title {
  font-size: 21px;
  font-family: "Assistant", sans-serif;
  color: ${(props) => props.theme.orangeST};
}

`;

const Img = styled.img`
border-radius: 0 !important;
`;

const Others = styled.div`
margin-top: 20px;
display: flex;
flex-wrap: wrap;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
gap: 0px;
color: ${(props) => props.theme.grey06ST};
font-family: "Assistant", sans-serif;
`;

export default function MembreRealisationsST({className}) {
const [showForm, setShowForm] = useState(false);
const [dataRealisations, setDataRealisations] = useState([]);
const [isLoading, setIsLoading] = useState(false);
function formatDuration(duration) {
  const [hours, minutes] = duration.split(':');
  const formattedHours = `${parseInt(hours)}h`;
  const formattedMinutes = minutes !== '00' ? `${parseInt(minutes)}` : '';
  return `${formattedHours}${formattedMinutes}`;
}
function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}
useEffect(() => {
  setIsLoading(true);
  axios.post(`/users/membrerealisationliste`)
  .then((response) => {
    console.log("response", response);
    setDataRealisations(response.data?.Realisations);
    setIsLoading(false);
  })
  .catch((err) => {
    console.log("err", err);
    setIsLoading(false);
  });
}, []);

const handleClose = (value) => {
  setShowForm(value);
}
    return (
        <Wrap className={className}>
          {isLoading && <SpinnerInlineST/>}
          {dataRealisations ? 
          <h3 className="center homemadeTitle initial orange">Mes réalisations</h3> : 
          <h3 className="center homemadeTitle initial orange">Vous n'avez pas de réalisations</h3>
          }
          <BoutonNoirST lib="Ajouter une réalisation" onClick={() => { setShowForm(true); }} />
          {showForm === true ?
          ReactDOM.createPortal(
            <WrapFormPop className="form"><FormRealisationST onChangeOpen={handleClose}/></WrapFormPop>,
                    document.getElementById('root')
          ) : null}
          <Cards>
            {
              dataRealisations && dataRealisations.map((item, index) => (
                <CardStyled
                  key={index}
                  style={{ width: 400 }}
                  // actions={[
                  //   <FaPen key="edit" />,
                  //   <FaTrashAlt key="setting" />,
                  //   <BsThreeDots key="ellipsis" />,
                  // ]}
                  cover={
                    <Img
                      src={item?.ImageTitre?.url ? item?.ImageTitre?.url : picDefault }
                      alt={item?.ImageTitre?.alt ? item?.ImageTitre?.alt : "Image de la réalisation"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = picDefault;
                      }}
                      loading="lazy"
                    />
                  }
                >
                  <Date>{formatDate(item?.Date)}</Date>
                  <MetaStyled
                    title={item?.Titre}
                    description={item?.Contenu}
                  />
                  <Others>
                    <span><b>Temps de réalisation : </b>{formatDuration(item?.TempsReal)}</span>
                    <span><b>Budget :</b> {item?.Budget} €</span>
                  </Others>
                  <Prods>
                    <Titre className="prod">Matériels :</Titre>
                    <p>{item?.Materiels}</p>
                    {/* <LinkProd>
                      <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
                      Tissu lycra mat Oeko-Tex uni
                    </LinkProd>
                    <LinkProd>
                      <ImageProd src="https://cdn.shopify.com/s/files/1/0827/2756/7701/files/tissu-lycra-mat-oeko-tex-uni-bleu-roi.jpg?v=1698412644"/>
                      Tissu lycra mat Oeko-Tex uni
                    </LinkProd> */}
                  </Prods>
                </CardStyled>
              ))
            }
         
          </Cards>
        </Wrap>
  );
}
