import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
const { Sider } = Layout;
const { SubMenu } = Menu;


const StyledSubMenu = styled(SubMenu)`
font-size: 16px !important;
color: ${(props) => props.theme.grey03ST} !important;
width: 100%;
border-radius: 0 !important;
padding: 5px 0;
&.img {
  text-transform: uppercase !important;
  &>div > span {
  display: flex;
  align-items: center;
  white-space: wrap;
  height: 50px !important;
  line-height: 23px !important;
  &> a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  }
}
&.img.subMenu {
  text-transform: initial !important;
}
&.niv1 {
  border-bottom: 1px solid ${(props) => props.theme.grey01ST};
  &>.ant-menu-submenu-title {
  height: 50px !important;
  line-height: 50px !important;
  }
}
&.niv2 {
  &>.ant-menu-submenu-title {
  height: 50px !important;
  line-height: 50px !important;
}
}


&.ant-menu-submenu-open > .ant-menu-submenu-title {
  color: ${(props) => props.theme.redST} !important;
  background-color: ${(props) => props.theme.rose01ST};
}
&.noSubMenu {
  &>div>i {
    display: none;
  }
}
li.ant-menu-item.ant-menu-item.ant-menu-item-only-child.niv4 { 
  font-size: 14px !important;
  &:hover {
    background-color: ${(props) => props.theme.rose01ST};
  }
}
li.ant-menu-item.ant-menu-item.ant-menu-item-only-child.niv2.img { 
  text-transform: uppercase !important;
}
li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.niv4 { 
  color: ${(props) => props.theme.redST} !important;
  background-color: ${(props) => props.theme.rose01ST};
}
&.ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: inherit !important;
}
&.ant-menu>.ant-menu-item-selected {
  color: ${(props) => props.theme.redST} !important;
}

&.ant-menu-submenu-selected >.ant-menu-submenu-title {
    &:active {
      background-color: ${(props) => props.theme.rose01ST};
    }
    &:hover {
      background-color: ${(props) => props.theme.rose01ST};
    }
  }
  &.ant-menu-submenu >.ant-menu-submenu-title {
    &:hover {
      background-color: ${(props) => props.theme.rose01ST};
    }
  }
`;
const StyledMenu = styled(Menu)`
width: 100%;
`;

const ImgStyled = styled.img`
padding: 10px;
width: 100%;
`;


const MenuDiv = styled.div`
width: 100%;
`;

const MenuItem = styled(Menu.Item)`
& > span > a {
  text-decoration: none;
}
`;

const LinkInside = styled.a`
display: block;
width: 100%;
color: inherit;
text-decoration: none;
&:hover {
  color: inherit;
}
`;

export default function DrawerMenu({ data }) {
    const [openKeys, setOpenKeys] = useState([]);
    
    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      const isNiv1 = latestOpenKey && latestOpenKey.startsWith('niv1-');
      setOpenKeys(isNiv1 ? [latestOpenKey] : keys);
    };
  
  
    
    return (
      <>
     
<MenuDiv>

<Sider width={"100%"}>
  <StyledMenu
    mode="inline"
    key={`niv0-0`}
    openKeys={openKeys}
    onOpenChange={onOpenChange}
    style={{ height: "100%", borderRight: 0 }}
    className={"niv0"}
  >
    {data &&
      data
        .sort((a, b) => (a.Tri < b.Tri ? -1 : 1))
        .map((menu, i) => (
          <StyledSubMenu id="menu"
            key={`niv1-${menu?.ID}`}
            title={!menu?.SousMenu ? <LinkInside href={menu?.slug}>{menu?.lib}</LinkInside> : menu?.lib}
            className={`niv1  ${menu?.SousMenu && menu?.SousMenu.length>0 ? "" : "noSubMenu"}`}
          >
            
            {(menu?.SousMenu || []).map((sous1, i) => (
            <>
              {sous1?.image &&<ImgStyled src={sous1?.image?.url}/>}
              <StyledSubMenu
                key={`niv2-${i}`}
                title={!sous1?.SousMenu ? <LinkInside href={sous1?.slug}>{sous1?.lib}</LinkInside> : sous1?.lib}
                className={`niv2 ${sous1?.image ? "img" : ""} ${sous1?.SousMenu && sous1?.SousMenu?.length>0 ? "subMenu" : "noSubMenu"}`}
              >
                {(sous1?.SousMenu || []).map((n3) => (
                    <StyledSubMenu key={`niv3-${n3?.ID}`} 
                    className={`niv3 ${n3?.SousMenu && n3?.SousMenu?.length>0 ? "" : "noSubMenu"}`} 
                    title={!n3?.SousMenu ? <LinkInside href={n3?.slug}>{n3?.lib}</LinkInside> : n3?.lib}
                    >
                      {n3?.SousMenu &&
                        n3?.SousMenu.map((n4, i) => (
                          <MenuItem
                          key={`niv4-${n4?.ID}`}
                            title={n4?.lib}
                            className={"niv4"}
                          > <a href={n4?.slug}>{n4?.lib}</a></MenuItem>
                        ))}
                    </StyledSubMenu>
                  ))}
              </StyledSubMenu>
              </>
        ))}
      </StyledSubMenu>
    ))}
   
     
  </StyledMenu>
</Sider>

</MenuDiv>
</>
    );
  }