import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {Wrap} from "./ElementsMembre";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import {FaCheck} from "react-icons/fa";
import {AiOutlineClose} from "react-icons/ai";

const News = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & p {
    font-size: 18px;
    & svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }
`;



export default function MembreNewsST({className}) {
  const user = useSelector(state => state?.user?.user?.Contact);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();

  const sendNewsInfo = (news) => {
    setIsLoading(true);
        axios.post(`/users/membresauvenewsletter`, {Newsletter: news})
          .then((response) => {
            console.log("response", response);
            setIsLoading(false);
            dispatch({
              type: "UPDATE_USER",
              payload: {
                Contact: {
                  ...user,
                  Newsletter: news,
                },
              },
            });
            setIsErrorVisible(true);
            setTxt("Votre demande a bien été prise en compte");
          })
          .catch((err) => {
            console.log("err", err);
            setIsLoading(false);
          });
    }
    return (
        <Wrap className={className}>
          {isLoading && <SpinnerST />}
          {isErrorVisible && <PopErrorST message={txt} titre={"Newsletter"} onClick={() => { setIsErrorVisible(false)}} />}
          <h3 className="center homemadeTitle initial orange">Mes newsletters</h3>
          {user?.Newsletter ?
            <News>
              <p><FaCheck className="green"/>Vous êtes inscrit à la Newsletter</p>
              <BoutonNoirBtnST lib="Se désinscrire" onClick={() => sendNewsInfo(false)} />
            </News>
            :
            <News>
              <p><AiOutlineClose className="red"/>Vous n'êtes pas inscrit à la newsletter</p>
              <BoutonNoirBtnST lib="S'inscrire" onClick={() => sendNewsInfo(true)} />
            </News>
            }
        </Wrap>
  );
}
